import { memo } from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import Tooltip from 'components/atoms/Tooltip'
import { useTemplateColors } from 'hooks/Template/useTemplateColors'
import { useNotification } from 'providers/Notification'

export interface TemplateColorsProps {
  templateId?: string
  defaultColor?: string
  size?: number
}

const TemplateColors = ({
  templateId,
  size = 12,
  defaultColor = '#ffffff',
}: TemplateColorsProps) => {
  const { successNotification } = useNotification()
  const { colors = {}, isLoading } = useTemplateColors(templateId)

  const width =
    (colors['primary-default'] ? size : 0) +
    (colors['primary-strong'] ? size : 0) +
    (colors['accent-default'] ? size : 0) +
    (colors['accent-strong'] ? size : 0)

  return (
    <SkeletonText loading={isLoading}>
      <div
        className="mx-auto flex overflow-hidden"
        style={{
          width,
        }}
      >
        {colors['primary-default'] ? (
          <Tooltip
            placement="bottom"
            title={colors['primary-default']?.toLowerCase() || defaultColor}
          >
            <div
              className="delay w-auto flex-1 cursor-pointer transition-all duration-300 hover:flex-[2_2_0%]"
              style={{
                height: size,
                backgroundColor: colors['primary-default'] || defaultColor,
              }}
              onClick={async (event) => {
                event.stopPropagation()
                event.preventDefault()
                await navigator.clipboard.writeText(
                  colors['primary-default']?.toLowerCase() || defaultColor
                )
                successNotification({
                  message: 'word.colorSuccessfullyCopiedToClipboard',
                })
              }}
            ></div>
          </Tooltip>
        ) : undefined}
        {colors['primary-strong'] ? (
          <Tooltip
            placement="bottom"
            title={colors['primary-strong']?.toLowerCase() || defaultColor}
          >
            <div
              className="delay w-auto flex-1 cursor-pointer transition-all duration-300 hover:flex-[2_2_0%]"
              style={{
                height: size,
                backgroundColor: colors['primary-strong'] || defaultColor,
              }}
              onClick={async (event) => {
                event.stopPropagation()
                event.preventDefault()
                await navigator.clipboard.writeText(
                  colors['primary-strong']?.toLowerCase() || defaultColor
                )
                successNotification({
                  message: 'word.colorSuccessfullyCopiedToClipboard',
                })
              }}
            ></div>
          </Tooltip>
        ) : undefined}
        {colors['accent-default'] ? (
          <Tooltip
            placement="bottom"
            title={colors['accent-default']?.toLowerCase() || defaultColor}
          >
            <div
              className="delay w-auto flex-1 cursor-pointer transition-all duration-300 hover:flex-[2_2_0%]"
              style={{
                height: size,
                backgroundColor: colors['accent-default'] || defaultColor,
              }}
              onClick={async (event) => {
                event.stopPropagation()
                event.preventDefault()
                await navigator.clipboard.writeText(
                  colors['accent-default']?.toLowerCase() || defaultColor
                )
                successNotification({
                  message: 'word.colorSuccessfullyCopiedToClipboard',
                })
              }}
            ></div>
          </Tooltip>
        ) : undefined}
        {colors['accent-strong'] ? (
          <Tooltip
            placement="bottom"
            title={colors['accent-strong']?.toLowerCase() || defaultColor}
          >
            <div
              className="delay w-auto flex-1 cursor-pointer transition-all duration-300 hover:flex-[2_2_0%]"
              style={{
                height: size,
                backgroundColor: colors['accent-strong'] || defaultColor,
              }}
              onClick={async (event) => {
                event.stopPropagation()
                event.preventDefault()
                await navigator.clipboard.writeText(
                  colors['accent-strong']?.toLowerCase() || defaultColor
                )
                successNotification({
                  message: 'word.colorSuccessfullyCopiedToClipboard',
                })
              }}
            ></div>
          </Tooltip>
        ) : undefined}
      </div>
    </SkeletonText>
  )
}

export default memo(TemplateColors)
