import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { CompanyType } from 'config/companies'
import { SERVICES } from 'config/services'
import { useCompanies } from 'providers/Companies'

export const useCompanyName = (companyId?: string) => {
  const { getCompany } = useCompanies()

  const result = useQuery<CompanyType>({
    enabled: Boolean(companyId),
    queryKey: [SERVICES.COMPANIES, companyId],
    queryFn: () => getCompany(companyId as string),
  })

  return useMemo(
    () => ({
      ...result,
      name: result?.data?.name,
    }),
    [result]
  )
}
