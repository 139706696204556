import { Component, ReactNode } from 'react'

import Button from 'components/atoms/Button'
import Center from 'components/atoms/Center'
import Result from 'components/atoms/Result'
import { isDevEnv } from 'utils/env'

export class ReactErrorProvider extends Component<
  {
    children: ReactNode
  },
  { hasError: boolean }
> {
  constructor(props: { children: ReactNode }) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    console.error(error)
    return { hasError: true }
  }

  render() {
    if (this.state.hasError && !isDevEnv) {
      return (
        <Center>
          <Result
            style={{ paddingTop: 100 }}
            status="500"
            subTitle="error.default"
            extra={
              <Button
                className="mt-10 min-w-full text-lg"
                type="primary"
                message="auth.backToHomePage"
                href={window.location.origin}
              />
            }
          />
        </Center>
      )
    }

    return this.props.children
  }
}
