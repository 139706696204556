// https://ant.design/components/tooltip/

import { Tooltip as AntdTooltip, TooltipProps as AntdTooltipProps } from 'antd'
import { memo } from 'react'

import { MessageType } from 'config/message'
import { useFormatMessage } from 'hooks/message'

export interface TooltipProps extends Omit<AntdTooltipProps, 'title'> {
  title?: MessageType
}

const Tooltip = ({ title, ...rest }: TooltipProps) => {
  const formatMessage = useFormatMessage()

  return (
    <AntdTooltip title={title ? formatMessage(title) : undefined} {...rest} />
  )
}

export default memo(Tooltip)
