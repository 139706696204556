import { memo } from 'react'

import Spin from 'components/atoms/Spin'

const PDFPreviewUrl = ({ fileUrl }: { fileUrl: string }) => {
  if (!fileUrl) {
    return (
      <div className="flex items-center justify-center">
        <Spin />
      </div>
    )
  }

  return (
    <embed
      type="application/pdf"
      src={fileUrl + '#toolbar=0'}
      width="100%"
      height="100%"
    />
  )
}

export default memo(PDFPreviewUrl)
