import { createContext, useContext } from 'react'

import { PaletteType } from 'config/palettes'

export type FindPalettesType = {
  limit: number
  skip: number
  total: number
  data: PaletteType[]
}

interface IPalettesContext {
  palettesService: any
  findPalettes: (params?: object) => Promise<FindPalettesType>
  getPalette: (id: string, params?: object) => Promise<PaletteType>
  createPalette: (data: PaletteType, params?: object) => Promise<PaletteType>
  updatePalette: (
    id: string,
    data: PaletteType,
    params?: object
  ) => Promise<PaletteType>
  removePalette: (id: string, params?: object) => Promise<PaletteType>
}

export const PalettesContext = createContext<IPalettesContext>({
  palettesService: {},
  findPalettes: () =>
    Promise.resolve({
      limit: 0,
      skip: 0,
      total: 0,
      data: [],
    }),
  getPalette: () => Promise.resolve({}),
  createPalette: () => Promise.resolve({}),
  updatePalette: () => Promise.resolve({}),
  removePalette: () => Promise.resolve({}),
})

export const usePalettes = (): IPalettesContext => useContext(PalettesContext)
