import React, { Dispatch, SetStateAction, useContext } from 'react'

import { THEMES, ThemesType } from 'config/themes'

interface IThemeContext {
  theme: ThemesType
  setTheme: Dispatch<SetStateAction<ThemesType>>
  toggleTheme: () => void
}

export const ThemeContext = React.createContext<IThemeContext>({
  theme: THEMES.LIGHT,
  setTheme: () => {},
  toggleTheme: () => {},
})

export const useTheme = (): IThemeContext => useContext(ThemeContext)
