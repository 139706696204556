import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import { AuthenticationType } from 'config/authentication'
import { LOCAL_STORAGE } from 'config/localStorage'
import { LoginType } from 'config/login'
import { SERVICES } from 'config/services'
import { SignupType } from 'config/signup'
import { USER_PLANS, USER_ROLES, UserType } from 'config/users'
import { useFeathers } from 'providers/Feathers'
import { UserContext } from 'providers/User/UserContext'
import { isEmptyObject, capitalizeFirstChars, isSet } from 'utils'

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const { client } = useFeathers()

  const [user, setUser] = useState<UserType | null | undefined>(undefined)

  const login = useCallback(
    ({ email, ...rest }: LoginType) =>
      client.authenticate({
        ...rest,
        email: email.trim().toLowerCase(),
        strategy: 'local',
      }),
    [client]
  )

  const signup = useCallback(
    ({ email, firstName, lastName, companyName, ...rest }: SignupType) =>
      client.service(SERVICES.USERS).create({
        ...rest,
        email: email.trim().toLowerCase(),
        firstName: capitalizeFirstChars(firstName),
        lastName: capitalizeFirstChars(lastName),
        companyName: capitalizeFirstChars(companyName),
      }),
    [client]
  )

  const logout = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE.REDIRECT_AFTER_LOGIN_PATH)
    localStorage.removeItem(LOCAL_STORAGE.REDIRECT_AFTER_LOGIN_TIME)
    client.logout()
    // localStorage.clear() // Be careful this remove all the localStorage (including lang, etc...)
    // sessionStorage.clear()
    // window.location.reload()
  }, [client])

  useEffect(() => {
    // Try to authenticate with the JWT stored in localStorage
    client.reAuthenticate().catch(() => {
      setUser(null)
    })

    // On successful login
    client.on('authenticated', async ({ user }: AuthenticationType) => {
      setUser(user)
      if (user?.id) {
        await client.service(SERVICES.USERS).patch(user?.id, {
          lastLoggedInAt: new Date(),
        })
      }
    })

    // On logout reset all all local state (which will then show the login screen)
    client.on('logout', () => {
      setUser(null)
    })
  }, [client])

  const context = useMemo(
    () => ({
      loading: user === undefined,
      user,
      setUser,
      isAuth: user ? !isEmptyObject(user) : false,
      isAdmin: user?.role === USER_ROLES.ADMIN,
      isOwner: user?.role === USER_ROLES.OWNER,
      isManager: user?.role === USER_ROLES.MANAGER,
      isUser: user?.role === USER_ROLES.USER,
      isFree: user?.plan === USER_PLANS.FREE,
      isPro: user?.plan === USER_PLANS.PRO,
      isProPlus: user?.plan === USER_PLANS.PROPLUS,
      isRoleIn: (roles: USER_ROLES[]) =>
        user?.role ? roles.includes(user?.role) : false,
      isRoleNotIn: (roles: USER_ROLES[]) =>
        Boolean(user?.role && !roles.includes(user?.role)),
      freeSignatures:
        user?.plan === USER_PLANS.FREE
          ? user?.freeSignatures ?? 1
          : Math.max(user?.freeSignatures || 1, 10),
      login,
      signup,
      logout,
    }),
    [user, login, signup, logout]
  )

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}
