import { memo, ReactNode, Suspense } from 'react'
import { Routes } from 'react-router-dom'

const RouterSwitch = ({
  fallback = null,
  children,
}: {
  fallback?: ReactNode
  children: ReactNode
}) => {
  return (
    <Suspense fallback={fallback}>
      <Routes>{children}</Routes>
    </Suspense>
  )
}

export default memo(RouterSwitch)
