import React, { Dispatch, SetStateAction, useContext } from 'react'

interface IPricingTableContext {
  seePricingTable: boolean
  setSeePricingTable: Dispatch<SetStateAction<boolean>>
}

export const PricingTableContext = React.createContext<IPricingTableContext>({
  seePricingTable: false,
  setSeePricingTable: () => {},
})

export const usePricingTable = (): IPricingTableContext =>
  useContext(PricingTableContext)
