import { ReactNode, useCallback, useMemo } from 'react'

import { SERVICES } from 'config/services'
import { SignatureType } from 'config/signatures'
import { useFeathers } from 'providers/Feathers'
import { SignaturesContext } from 'providers/Signatures/SignaturesContext'

export const SignaturesProvider = ({ children }: { children: ReactNode }) => {
  const { client } = useFeathers()

  const signaturesService = client.service(SERVICES.SIGNATURES)

  const findSignatures = useCallback(
    (params?: object) => signaturesService.find(params),
    [signaturesService]
  )

  const getSignature = useCallback(
    (id: string, params?: object) => signaturesService.get(id, params),
    [signaturesService]
  )

  const createSignature = useCallback(
    (data: SignatureType, params?: object) =>
      signaturesService.create(data, params),
    [signaturesService]
  )

  const updateSignature = useCallback(
    (id: string, data: SignatureType, params?: object) =>
      signaturesService.patch(id, data, params),
    [signaturesService]
  )

  const removeSignature = useCallback(
    (id: string, params?: object) => signaturesService.remove(id, params),
    [signaturesService]
  )

  const context = useMemo(
    () => ({
      signaturesService,
      findSignatures,
      getSignature,
      createSignature,
      updateSignature,
      removeSignature,
    }),
    [
      signaturesService,
      findSignatures,
      getSignature,
      createSignature,
      updateSignature,
      removeSignature,
    ]
  )

  return (
    <SignaturesContext.Provider value={context}>
      {children}
    </SignaturesContext.Provider>
  )
}
