export enum SLUGS {
  EDIT_BANNER = 'editBanner',
  EDIT_COMPANY = 'editCompany',
  EDIT_PALETTE = 'editPalette',
  EDIT_SIGNATURE_TEAM = 'editSignatureTeam',
  EDIT_TEAM = 'editTeam',
  EDIT_TEAM_USER = 'editTeamUser',
  EDIT_TEMPLATE = 'editTemplate',
  EDIT_USER = 'editUser',
  INVITE_USER = 'inviteUser',
  RENAME_SIGNATURE = 'renameSignature',
  SEE_GUIDE = 'seeGuide',
}
