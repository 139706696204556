// https://ant.design/components/checkbox/

import { Switch as AntdSwitch, SwitchProps as AntdSwitchProps } from 'antd'
import { memo } from 'react'

import { MessageType } from 'config/message'
import { useFormatMessage } from 'hooks/message'

export interface SwitchProps
  extends Omit<
    AntdSwitchProps,
    'value' | 'checkedChildren' | 'unCheckedChildren'
  > {
  value?: boolean
  checkedChildren?: MessageType | null
  unCheckedChildren?: MessageType | null
}

const Switch = ({
  className,
  checkedChildren = 'word.yes',
  unCheckedChildren = 'word.no',
  value,
  ...rest
}: SwitchProps) => {
  const formatMessage = useFormatMessage()

  return (
    <AntdSwitch
      className={`${value ? '' : 'bg-zinc-400'} ` + className}
      checked={value}
      checkedChildren={
        checkedChildren ? formatMessage(checkedChildren) : undefined
      }
      unCheckedChildren={
        unCheckedChildren ? formatMessage(unCheckedChildren) : undefined
      }
      {...rest}
    />
  )
}

export default memo(Switch)
