import { createContext, useContext } from 'react'

import { BannerType } from 'config/banners'

export type FindBannersType = {
  limit: number
  skip: number
  total: number
  data: BannerType[]
}

interface IBannersContext {
  bannersService: any
  findBanners: (params?: object) => Promise<FindBannersType>
  getBanner: (id: string, params?: object) => Promise<BannerType>
  createBanner: (data: BannerType, params?: object) => Promise<BannerType>
  updateBanner: (
    id: string,
    data: BannerType,
    params?: object
  ) => Promise<BannerType>
  removeBanner: (id: string, params?: object) => Promise<BannerType>
}

export const BannersContext = createContext<IBannersContext>({
  bannersService: {},
  findBanners: () =>
    Promise.resolve({
      limit: 0,
      skip: 0,
      total: 0,
      data: [],
    }),
  getBanner: () => Promise.resolve({}),
  createBanner: () => Promise.resolve({}),
  updateBanner: () => Promise.resolve({}),
  removeBanner: () => Promise.resolve({}),
})

export const useBanners = (): IBannersContext => useContext(BannersContext)
