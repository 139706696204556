import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { SERVICES } from 'config/services'
import { TemplateType } from 'config/templates'
import { useTemplates } from 'providers/Templates'

export const useTemplateFormat = (templateId?: string) => {
  const { getTemplate } = useTemplates()

  const result = useQuery<TemplateType>({
    enabled: Boolean(templateId),
    queryKey: [SERVICES.TEMPLATES, templateId],
    queryFn: () => getTemplate(templateId as string),
  })

  return useMemo(
    () => ({
      ...result,
      format: result?.data?.format,
    }),
    [result]
  )
}
