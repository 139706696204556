import {
  User,
  Colorfilter,
  Personalcard,
  Home2,
  ArrowSquare,
  ArrowSquareUp,
  MouseSquare,
} from 'iconsax-react'
import { memo, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import Menu from 'components/atoms/Menu'
import { ROUTES } from 'config/routes'
import { useSignatureParentSignatureId } from 'hooks/Signature/useSignatureParentSignatureId'
import { cleanArray } from 'utils'

function Sider({
  showMenu,
  collapsed,
  setCollapsed,
}: {
  showMenu: boolean
  collapsed: boolean
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const location = useLocation()
  const { signatureId }: { signatureId?: string } = useParams()
  const { parentSignatureId } = useSignatureParentSignatureId(signatureId)

  const defaultKey = useMemo(
    () => location?.pathname?.match(/^\/(.+?)(?:\/.+)?$/)?.[1] || 'dashboard',
    [location]
  )

  const menu = useMemo(
    () =>
      cleanArray([
        {
          className: 'border h-[44px] px-[12px] py-[5.5px] mb-8',
          icon: <Home2 size={22} />,
          label: 'word.home',
          to: '/',
          key: 'home',
        },
        {
          className: 'border border-transparent h-[44px] px-[12px] py-[5.5px]',
          icon: <User size={22} />,
          label: 'word.myInformations',
          to: `/${ROUTES.DETAILS}/${signatureId}`,
          key: 'details',
        },
        !parentSignatureId
          ? {
              className:
                'border border-transparent h-[44px] px-[12px] py-[5.5px]',
              icon: <Colorfilter size={22} />,
              label: 'word.palettes',
              to: `/${ROUTES.PALETTES}/${signatureId}`,
              key: 'palettes',
            }
          : undefined,
        !parentSignatureId
          ? {
              className:
                'border border-transparent h-[44px] px-[12px] py-[5.5px]',
              icon: <Personalcard size={22} />,
              label: 'word.templates',
              to: `/${ROUTES.TEMPLATES}/${signatureId}`,
              key: 'templates',
            }
          : undefined,
        !parentSignatureId
          ? {
              className:
                'border border-transparent h-[44px] px-[12px] py-[5.5px]',
              icon: <MouseSquare size={22} />,
              label: 'word.banners',
              to: `/${ROUTES.BANNERS}/${signatureId}`,
              key: 'banners',
            }
          : undefined,
      ]),
    [signatureId, parentSignatureId]
  )

  return (
    <Menu
      mode="inline"
      className={`fixed bottom-0 top-0 space-y-3 px-3 py-4 ${
        showMenu ? 'block' : 'hidden'
      } ${collapsed ? 'w-[80px]' : 'w-[230px]'}`}
      selectedKeys={defaultKey ? [defaultKey] : undefined}
      inlineCollapsed={collapsed}
      items={menu}
      onMouseEnter={() => {
        setCollapsed(false)
      }}
      onMouseLeave={() => {
        setCollapsed(true)
      }}
    />
  )
}

export default memo(Sider)
