import { addDays, addMonths, addYears } from 'date-fns'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { ONE_MINUTE } from 'config/date'

export enum DurationUnit {
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
}

dayjs.extend(utc)
dayjs.extend(timezone)

export const formatDate = (date: Date) => {
  return dayjs(date).format('YYYY-MM-DD')
}

export const formatTime = (date: Date) => {
  return dayjs(date).format('HH:mm')
}

export const today = () => {
  return formatDate(new Date())
}

export const getDateIn = (
  number: number,
  unit = DurationUnit.DAYS,
  startDate = new Date()
) => {
  switch (unit) {
    case DurationUnit.DAYS:
      return addDays(startDate, number)
    case DurationUnit.MONTHS:
      return addMonths(startDate, number)
    case DurationUnit.YEARS:
      return addYears(startDate, number)
    default:
      return null
  }
}

export const isDate = (value: any) => {
  const date = new Date(value)

  return !isNaN(date.getTime())
}

export const stringToDate = (value: Date | string) => {
  const date = new Date(value)

  if (isNaN(date.getTime())) {
    return undefined
  }

  if (typeof value === 'object') {
    return value
  }

  return new Date(date.valueOf() + date.getTimezoneOffset() * ONE_MINUTE)
}

export const getTimestamp = (value: Date | string) => {
  return stringToDate(value)?.getTime()
}

export const getTimezoneLabel = (timezone: string, name: string = timezone) => {
  const tz = dayjs().tz(timezone)

  const min = tz.utcOffset()
  const hr = `${(min / 60) ^ 0}:` + (min % 60 === 0 ? '00' : Math.abs(min % 60))
  return `(GMT${hr.includes('-') ? hr : `+${hr}`}) ${name}`
}
