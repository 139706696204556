import { ReactNode, useMemo, useState } from 'react'

import Modal from 'components/atoms/Modal'
import { PricingTableContext } from 'providers/PricingTable/PricingTableContext'
import { useUser } from 'providers/User'

export const PricingTableProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useUser()
  const [seePricingTable, setSeePricingTable] = useState<boolean>(false)

  const context = useMemo(
    () => ({
      seePricingTable,
      setSeePricingTable,
    }),
    [seePricingTable]
  )

  return (
    <PricingTableContext.Provider value={context}>
      {children}
      <Modal
        centered
        width="80%"
        open={seePricingTable}
        onCancel={() => setSeePricingTable(false)}
        footer={null}
      >
        {/* @ts-ignore */}
        <stripe-pricing-table
          client-reference-id={user?.id}
          customer-email={user?.email}
          publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
          pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
        />
      </Modal>
    </PricingTableContext.Provider>
  )
}
