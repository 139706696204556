import { memo } from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import { useCompanyName } from 'hooks/Company/useCompanyName'

export interface CompanyNameProps {
  companyId?: string
  withId?: boolean
}

const CompanyName = ({ companyId }: CompanyNameProps) => {
  const { name, isLoading } = useCompanyName(companyId)

  return <SkeletonText loading={isLoading}>{name}</SkeletonText>
}

export default memo(CompanyName)
