import { memo, ReactNode } from 'react'

const Center = ({
  children,
  className = '',
  ...rest
}: {
  className?: string
  children: ReactNode
}) => {
  return (
    <div
      className={`flex items-center justify-center text-center ` + className}
      {...rest}
    >
      {children}
    </div>
  )
}

export default memo(Center)
