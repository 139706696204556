export enum ROUTES {
  // Auth
  LOGIN = 'login',
  LOGOUT = 'logout',
  SIGNUP = 'signup',
  RESET_PASSWORD = 'reset-password',
  JOIN_INVITATION = 'invitation',

  // Flow
  INTRO_LOGO = 'intro-logo',
  INTRO_PALETTES = 'intro-palettes',
  INTRO_TEMPLATES = 'intro-templates',
  DETAILS = 'details',
  PALETTES = 'palettes',
  TEMPLATES = 'templates',
  BANNERS = 'banners',

  // Home
  ANALYTICS = 'analytics',
  PROFILE = 'profile',
  TEAMS = 'teams',
  SIGNATURE = 'signature',
  USERS = 'users',

  // Admin
  ADMIN_ANALYTICS = 'admin-analytics',
  ADMIN_COMPANIES = 'admin-companies',
  ADMIN_USERS = 'admin-users',
  ADMIN_CODES = 'admin-codes',
  ADMIN_SIGNATURES = 'admin-signatures',
  ADMIN_SIGNATURE_BANNERS = 'admin-signature-banners',
  ADMIN_PALETTES = 'admin-palettes',
  ADMIN_TEAMS = 'admin-teams',
  ADMIN_TEMPLATES = 'admin-templates',
  ADMIN_BANNERS = 'admin-banners',
}
