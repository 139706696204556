// https://ant.design/components/skeleton/

import { memo } from 'react'

import SkeletonButton, {
  SkeletonButtonProps,
} from 'components/atoms/Skeleton/Button'

const SkeletonText = (props: SkeletonButtonProps) => {
  return <SkeletonButton active size="small" {...props} />
}

export default memo(SkeletonText)
