import { createContext, useContext } from 'react'

import { CompanyType } from 'config/companies'

export type FindCompaniesType = {
  limit: number
  skip: number
  total: number
  data: CompanyType[]
}

interface ICompaniesContext {
  companiesService: any
  findCompanies: (params?: object) => Promise<FindCompaniesType>
  getCompany: (id: string, params?: object) => Promise<CompanyType>
  createCompany: (data: CompanyType, params?: object) => Promise<CompanyType>
  updateCompany: (
    id: string,
    data: CompanyType,
    params?: object
  ) => Promise<CompanyType>
  removeCompany: (id: string, params?: object) => Promise<CompanyType>
}

export const CompaniesContext = createContext<ICompaniesContext>({
  companiesService: {},
  findCompanies: () =>
    Promise.resolve({
      limit: 0,
      skip: 0,
      total: 0,
      data: [],
    }),
  getCompany: () => Promise.resolve({}),
  createCompany: () => Promise.resolve({}),
  updateCompany: () => Promise.resolve({}),
  removeCompany: () => Promise.resolve({}),
})

export const useCompanies = (): ICompaniesContext =>
  useContext(CompaniesContext)
