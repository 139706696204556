// https://ant.design/components/button/

import { Button as AntdButton } from 'antd'
import { ButtonProps as AntdButtonProps } from 'antd/es/button'
import { memo } from 'react'

import { MessageType } from 'config/message'
import { useFormatMessage } from 'hooks/message'

export interface ButtonProps extends AntdButtonProps {
  overrideClassName?: string
  to?: string
  message?: MessageType
  myRef?: any
}

const Button = ({
  to,
  icon,
  children,
  className = '',
  overrideClassName = '',
  message,
  myRef,
  ...rest
}: ButtonProps) => {
  const formatMessage = useFormatMessage()
  return (
    <AntdButton
      className={
        overrideClassName ||
        'flex min-h-[48px] items-center justify-center rounded-lg shadow-sm transition duration-200 hover:shadow-md ' +
          className
      }
      icon={icon && <span>{icon || undefined}</span>}
      ref={myRef}
      {...rest}
    >
      {children || formatMessage(message)}
    </AntdButton>
  )
}

export default memo(Button)
