import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { SERVICES } from 'config/services'
import { SignatureType } from 'config/signatures'
import { useSignatures } from 'providers/Signatures'

export const useSignatureParentSignatureId = (signatureId?: string) => {
  const { getSignature } = useSignatures()

  const result = useQuery<SignatureType>({
    enabled: Boolean(signatureId),
    queryKey: [SERVICES.SIGNATURES, signatureId],
    queryFn: () => getSignature(signatureId as string),
  })

  return useMemo(
    () => ({
      ...result,
      parentSignatureId: result?.data?.parentSignatureId,
    }),
    [result]
  )
}
