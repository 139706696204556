import { ReactNode, useCallback, useMemo } from 'react'

import { GmailIntegrationType } from 'config/gmailIntegration'
import { SERVICES } from 'config/services'
import { useFeathers } from 'providers/Feathers'
import { GmailIntegrationContext } from 'providers/GmailIntegration/GmailIntegrationContext'

export const GmailIntegrationProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const { client } = useFeathers()

  const GmailIntegrationService = client.service(SERVICES.GMAIL_INTEGRATION)

  const gmailIntegration = useCallback(
    (data: GmailIntegrationType, params?: object) =>
      GmailIntegrationService.create(data, params),
    [GmailIntegrationService]
  )

  const context = useMemo(
    () => ({
      GmailIntegrationService,
      gmailIntegration,
    }),
    [GmailIntegrationService, gmailIntegration]
  )

  return (
    <GmailIntegrationContext.Provider value={context}>
      {children}
    </GmailIntegrationContext.Provider>
  )
}
