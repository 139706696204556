// https://ant.design/components/space/

import { Space as AntdSpace } from 'antd'
import { SpaceProps as AntdSpaceProps } from 'antd/es/space'
import { memo, ReactNode } from 'react'

export interface SpaceProps extends AntdSpaceProps {
  children: ReactNode
}

const Space = ({ style, direction, ...rest }: SpaceProps) => {
  return (
    <AntdSpace
      style={{
        width: direction === 'vertical' ? '100%' : undefined,
        ...style,
      }}
      direction={direction}
      {...rest}
    />
  )
}

export default memo(Space)
