// https://ant.design/components/notification/

import { notification } from 'antd'
import { ReactNode, useCallback, useMemo } from 'react'

import { useFormatMessage } from 'hooks/message'
import {
  INotificationContext,
  NotificationConfigType,
  NotificationContext,
} from 'providers/Notification/NotificationContext'

notification.config({
  placement: 'bottomLeft',
})

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const formatMessage = useFormatMessage()
  const [api, contextHolder] = notification.useNotification()

  const sendNotification = useCallback(
    (type: string) =>
      ({ description, message, key, ...rest }: NotificationConfigType) => {
        if (type in api) {
          // @ts-ignore
          api[type]({
            key,
            description: description ? formatMessage(description) : undefined,
            message: message ? formatMessage(message) : undefined,
            placement: 'bottomLeft',
            ...rest,
          })
        }
      },
    [formatMessage, api]
  )

  const context: INotificationContext = useMemo(
    () => ({
      sendNotification,
      errorNotification: sendNotification('error'),
      infoNotification: sendNotification('info'),
      openNotification: sendNotification('open'),
      successNotification: sendNotification('success'),
      warningNotification: sendNotification('warning'),
      destroyNotification: () => {
        notification.destroy()
      },
    }),
    [sendNotification]
  )

  return (
    <NotificationContext.Provider value={context}>
      {children}
      {contextHolder}
    </NotificationContext.Provider>
  )
}
