import React, { useContext } from 'react'

import { TabType } from 'providers/Drawers'

interface IDrawerContext {
  name: string
  id: string
  tab: string
  index: number
  closeDrawer: () => void
  changeTab: (tab: TabType) => void
}

export const DrawerContext = React.createContext<IDrawerContext>({
  name: '',
  id: '',
  tab: '',
  index: 0,
  closeDrawer: () => {},
  changeTab: () => {},
})

export const useDrawer = (): IDrawerContext => useContext(DrawerContext)
