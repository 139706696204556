import { Sms } from 'iconsax-react'
import { memo } from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import Space from 'components/atoms/Space'
import { useUserEmail } from 'hooks/User/useUserEmail'

export interface UserEmailProps {
  userId?: string
  withIcon?: boolean
  fallbackValue?: string
}

const UserEmail = ({ userId, withIcon, fallbackValue }: UserEmailProps) => {
  const { email, isLoading } = useUserEmail(userId)

  return (
    <SkeletonText loading={isLoading}>
      {email ? (
        <Space>
          {withIcon && <Sms size={16} className="text-[#939393]" />}
          {email}
        </Space>
      ) : (
        fallbackValue
      )}
    </SkeletonText>
  )
}

export default memo(UserEmail)
