import { createContext, useContext } from 'react'

import { TeamUserType } from 'config/teamUsers'

export type FindTeamUsersType = {
  limit: number
  skip: number
  total: number
  data: TeamUserType[]
}

interface ITeamUsersContext {
  teamUsersService: any
  findTeamUsers: (params?: object) => Promise<FindTeamUsersType>
  getTeamUser: (id: string, params?: object) => Promise<TeamUserType>
  createTeamUser: (
    data: TeamUserType | TeamUserType[],
    params?: object
  ) => Promise<TeamUserType>
  updateTeamUser: (
    id: string,
    data: TeamUserType,
    params?: object
  ) => Promise<TeamUserType>
  removeTeamUser: (id: string, params?: object) => Promise<TeamUserType>
}

export const TeamUsersContext = createContext<ITeamUsersContext>({
  teamUsersService: {},
  findTeamUsers: () =>
    Promise.resolve({
      limit: 0,
      skip: 0,
      total: 0,
      data: [],
    }),
  getTeamUser: () => Promise.resolve({}),
  createTeamUser: () => Promise.resolve({}),
  updateTeamUser: () => Promise.resolve({}),
  removeTeamUser: () => Promise.resolve({}),
})

export const useTeamUsers = (): ITeamUsersContext =>
  useContext(TeamUsersContext)
