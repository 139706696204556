import { memo } from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import { useUserFirstName } from 'hooks/User/useUserFirstName'
import { useUserLastName } from 'hooks/User/useUserLastName'

export interface UserNameProps {
  userId?: string
  withId?: boolean
}

const UserName = ({ userId }: UserNameProps) => {
  const { firstName, isLoading: isLoadingFirstName } = useUserFirstName(userId)
  const { lastName, isLoading: isLoadingLastName } = useUserLastName(userId)

  return (
    <SkeletonText loading={isLoadingFirstName || isLoadingLastName}>
      {firstName} {lastName}
    </SkeletonText>
  )
}

export default memo(UserName)
