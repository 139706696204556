import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { ROUTES } from 'config/routes'
import { USER_ROLES } from 'config/users'

const Analytics = lazy(() => import('routes/Home/Content/Analytics'))
const Profile = lazy(() => import('routes/Home/Content/Profile'))
const Teams = lazy(() => import('routes/Home/Content/Teams'))
const Signature = lazy(() => import('routes/Home/Content/Signatures/Signature'))
const Signatures = lazy(() => import('routes/Home/Content/Signatures'))
const Users = lazy(() => import('routes/Home/Content/Users'))

const AdminAnalytics = lazy(() => import('routes/Home/Content/Admin/Analytics'))
const AdminCompanies = lazy(() => import('routes/Home/Content/Admin/Companies'))
const AdminUsers = lazy(() => import('routes/Home/Content/Admin/Users'))
const AdminTeams = lazy(() => import('routes/Home/Content/Admin/Teams'))
const AdminSignatures = lazy(
  () => import('routes/Home/Content/Admin/Signatures')
)
const AdminSignatureBanners = lazy(
  () => import('routes/Home/Content/Admin/SignatureBanners')
)
const AdminPalettes = lazy(() => import('routes/Home/Content/Admin/Palettes'))
const AdminTemplates = lazy(() => import('routes/Home/Content/Admin/Templates'))
const AdminBanners = lazy(() => import('routes/Home/Content/Admin/Banners'))

export const HomeRoutes = [
  {
    path: ROUTES.ADMIN_ANALYTICS,
    element: <AdminAnalytics />,
    roles: [USER_ROLES.ADMIN],
  },
  {
    path: ROUTES.ADMIN_COMPANIES,
    element: <AdminCompanies />,
    roles: [USER_ROLES.ADMIN],
  },
  {
    path: ROUTES.ADMIN_USERS,
    element: <AdminUsers />,
    roles: [USER_ROLES.ADMIN],
  },
  {
    path: ROUTES.ADMIN_TEAMS,
    element: <AdminTeams />,
    roles: [USER_ROLES.ADMIN],
  },
  {
    path: ROUTES.ADMIN_SIGNATURES,
    element: <AdminSignatures />,
    roles: [USER_ROLES.ADMIN],
  },
  {
    path: ROUTES.ADMIN_SIGNATURE_BANNERS,
    element: <AdminSignatureBanners />,
    roles: [USER_ROLES.ADMIN],
  },
  {
    path: ROUTES.ADMIN_PALETTES,
    element: <AdminPalettes />,
    roles: [USER_ROLES.ADMIN],
  },
  {
    path: ROUTES.ADMIN_TEMPLATES,
    element: <AdminTemplates />,
    roles: [USER_ROLES.ADMIN],
  },
  {
    path: ROUTES.ADMIN_BANNERS,
    element: <AdminBanners />,
    roles: [USER_ROLES.ADMIN],
  },
  {
    path: ROUTES.USERS,
    element: <Users />,
    roles: [USER_ROLES.ADMIN, USER_ROLES.OWNER, USER_ROLES.MANAGER],
  },
  {
    path: ROUTES.TEAMS,
    element: <Teams />,
    roles: [USER_ROLES.ADMIN, USER_ROLES.OWNER, USER_ROLES.MANAGER],
  },
  {
    path: ROUTES.ANALYTICS,
    element: <Analytics />,
    roles: [USER_ROLES.ADMIN],
  },
  {
    path: ROUTES.PROFILE,
    element: <Profile />,
  },
  {
    path: `${ROUTES.SIGNATURE}/:signatureId`,
    element: <Signature />,
  },
  {
    path: '/',
    element: <Signatures />,
  },
  { path: '*', element: <Navigate to={`/`} replace /> },
]
