export enum LOCAL_STORAGE {
  COLUMNS_ADMIN_COMPANIES = 'columns-admin-companies',
  FILTERS_ADMIN_COMPANIES = 'filters-admin-companies',
  SEARCH_ADMIN_COMPANIES = 'search-admin-companies',
  COLUMNS_ADMIN_TEAMS = 'columns-admin-teams',
  FILTERS_ADMIN_TEAMS = 'filters-admin-teams',
  SEARCH_ADMIN_TEAMS = 'search-admin-teams',
  COLUMNS_ADMIN_USERS = 'columns-admin-users',
  FILTERS_ADMIN_USERS = 'filters-admin-users',
  SEARCH_ADMIN_USERS = 'search-admin-users',
  COLUMNS_ADMIN_SIGNATURES = 'columns-admin-signatures',
  FILTERS_ADMIN_SIGNATURES = 'filters-admin-signatures',
  SEARCH_ADMIN_SIGNATURES = 'search-admin-signatures',
  COLUMNS_ADMIN_SIGNATURE_BANNERS = 'columns-admin-signature-banners',
  FILTERS_ADMIN_SIGNATURE_BANNERS = 'filters-admin-signature-banners',
  SEARCH_ADMIN_SIGNATURE_BANNERS = 'search-admin-signature-banners',
  COLUMNS_ADMIN_PALETTES = 'columns-admin-palettes',
  FILTERS_ADMIN_PALETTES = 'filters-admin-palettes',
  SEARCH_ADMIN_PALETTES = 'search-admin-palettes',
  COLUMNS_ADMIN_TEMPLATES = 'columns-admin-templates',
  FILTERS_ADMIN_TEMPLATES = 'filters-admin-templates',
  SEARCH_ADMIN_TEMPLATES = 'search-admin-templates',
  COLUMNS_ADMIN_BANNERS = 'columns-admin-banners',
  FILTERS_ADMIN_BANNERS = 'filters-admin-banners',
  SEARCH_ADMIN_BANNERS = 'search-admin-banners',
  COLUMNS_TEAMS = 'columns-teams',
  FILTERS_TEAMS = 'filters-teams',
  SEARCH_TEAMS = 'search-teams',
  COLUMNS_USERS = 'columns-users',
  FILTERS_USERS = 'filters-users',
  SEARCH_USERS = 'search-users',
  INTRO = 'intro', // TODO: remove it, useless now
  LOCALE = 'locale',
  MENU = 'menu',
  SOURCE = 'source',
  THEME = 'theme',
  TOKEN_ID = 'token-id',
  REDIRECT_AFTER_LOGIN_PATH = 'redirect-after-login-path',
  REDIRECT_AFTER_LOGIN_TIME = 'redirect-after-login-time',
}
