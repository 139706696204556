import { memo } from 'react'

import { MessageType } from 'config/message'
import { useFormatMessage } from 'hooks/message'

const Message = ({ value }: { value: MessageType }) => {
  const formatMessage = useFormatMessage()

  return <>{formatMessage(value)}</>
}

export default memo(Message)
