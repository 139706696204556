// https://ant.design/components/collapse/

import { Collapse as AntdCollapse } from 'antd'
import { CollapseProps as AntdCollapseProps } from 'antd/lib/collapse'
import React, { ReactNode } from 'react'

export interface CollapseProps extends AntdCollapseProps {
  children: ReactNode
}

const Collapse = (props: CollapseProps) => {
  return <AntdCollapse {...props} />
}

export default React.memo(Collapse)
