import { get, set } from 'lodash'
import { lazy, ReactNode, Suspense, useCallback, useState } from 'react'

import Spin from 'components/atoms/Spin'
import { useUser } from 'providers/User'
import {
  WorkflowContext,
  WorkflowType,
} from 'providers/Workflows/WorkflowContext'
import { WorkflowsContext } from 'providers/Workflows/WorkflowsContext'
import { dropRight, last } from 'utils'

const fallback = (
  <div className="flex items-center justify-center">
    <Spin />
  </div>
)

export const WorkflowsProvider = ({ children }: { children: ReactNode }) => {
  const { isAuth } = useUser()

  const [loadedComponents, setLoadedComponents] = useState({})
  const [workflows, setWorkflows] = useState<WorkflowType[]>([])

  const getComponent = useCallback(
    (slug: string) => {
      const component = get(loadedComponents, slug)

      if (component) {
        return component
      }

      try {
        const component = lazy(() =>
          import(`components/workflows/${slug}`).catch((error) => {
            throw new Error(error)
          })
        )

        setLoadedComponents((loadedComponents) =>
          set(loadedComponents, slug, component)
        )

        return component
      } catch (error) {
        throw new Error(`Workflow "${slug}" not found`)
      }
    },
    [loadedComponents]
  )

  const startWorkflow = useCallback(
    ({
      slug,
      data = {},
      onCompleted = () => {},
    }: {
      slug: string
      data?: Record<string, any>
      onCompleted?: (data?: any) => void
    }) => {
      if (!isAuth) {
        return
      }

      const component = getComponent(slug)
      const workflow = { data, component, onCompleted }
      setWorkflows((w) => [...w, workflow])
    },
    [isAuth, getComponent]
  )

  const closeWorkflow = useCallback((): void => {
    setWorkflows(dropRight)
  }, [])

  const finishWorkflow = useCallback(
    (data: any): void => {
      const lastWorkflow = last(workflows)
      if (lastWorkflow?.onCompleted) {
        lastWorkflow.onCompleted(data)
      }
    },
    [workflows]
  )

  return (
    <WorkflowsContext.Provider value={{ workflows, startWorkflow }}>
      {children}
      {workflows?.map((workflow, index) => {
        const { component: Component } = workflow || {}

        if (!workflow) {
          return null
        }

        return (
          <WorkflowContext.Provider
            key={index}
            value={{
              loading: false,
              data: workflow?.data,
              closeWorkflow,
              finishWorkflow,
            }}
          >
            <Suspense fallback={workflow ? null : fallback}>
              {Component && <Component />}
            </Suspense>
          </WorkflowContext.Provider>
        )
      })}
    </WorkflowsContext.Provider>
  )
}
