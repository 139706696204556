import { lazy } from 'react'

import { UserDrawerTab } from 'components/drawers/User'
import { DRAWERS } from 'config/drawers'

const User = lazy(() => import('components/drawers/User'))

export const DRAWER_DEFAULT_TAB = {
  [DRAWERS.user]: UserDrawerTab.details,
}

export const component = {
  [DRAWERS.user]: User,
}
