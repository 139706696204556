export enum SERVICES {
  AUTH_MANAGEMENT = 'auth-management',
  BANNERS = 'banners',
  COMPANIES = 'companies',
  CONVERT_SIGNATURES = 'convert-signatures',
  FONTS = 'fonts',
  GMAIL_INTEGRATION = 'gmail-integration',
  PALETTES = 'palettes',
  RECAPTCHA = 'recaptcha',
  SIGNATURE_BANNERS = 'signature-banners',
  SIGNATURES = 'signatures',
  STRIPE = 'stripe',
  SUBSCRIPTIONS = 'subscriptions',
  TEAM_USERS = 'team-users',
  TEAMS = 'teams',
  TEMPLATES = 'templates',
  UPLOAD = 'upload',
  UPLOAD_PUBLIC = 'upload-public',
  USERS = 'users',
}
