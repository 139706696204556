import { createContext, useContext } from 'react'

import { TemplateType } from 'config/templates'

export type FindTemplatesType = {
  limit: number
  skip: number
  total: number
  data: TemplateType[]
}

interface ITemplatesContext {
  templatesService: any
  findTemplates: (params?: object) => Promise<FindTemplatesType>
  getTemplate: (id: string, params?: object) => Promise<TemplateType>
  createTemplate: (data: TemplateType, params?: object) => Promise<TemplateType>
  updateTemplate: (
    id: string,
    data: TemplateType,
    params?: object
  ) => Promise<TemplateType>
  removeTemplate: (id: string, params?: object) => Promise<TemplateType>
}

export const TemplatesContext = createContext<ITemplatesContext>({
  templatesService: {},
  findTemplates: () =>
    Promise.resolve({
      limit: 0,
      skip: 0,
      total: 0,
      data: [],
    }),
  getTemplate: () => Promise.resolve({}),
  createTemplate: () => Promise.resolve({}),
  updateTemplate: () => Promise.resolve({}),
  removeTemplate: () => Promise.resolve({}),
})

export const useTemplates = (): ITemplatesContext =>
  useContext(TemplatesContext)
