import { useEffect } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'

import { ONE_MINUTE } from 'config/date'
import { LOCAL_STORAGE } from 'config/localStorage'
import { useUser } from 'providers/User'

export const ProtectedRoute = () => {
  const navigate = useNavigate()
  const { isAuth } = useUser()

  useEffect(() => {
    const now = new Date().getTime()
    const { pathname, search } = window.location

    if (!isAuth) {
      if (pathname !== '/') {
        localStorage.setItem(
          LOCAL_STORAGE.REDIRECT_AFTER_LOGIN_PATH,
          pathname + search
        )
        localStorage.setItem(
          LOCAL_STORAGE.REDIRECT_AFTER_LOGIN_TIME,
          now.toString()
        )
      }
    } else {
      if (
        now - localStorage[LOCAL_STORAGE.REDIRECT_AFTER_LOGIN_TIME] <
        ONE_MINUTE
      ) {
        navigate(localStorage[LOCAL_STORAGE.REDIRECT_AFTER_LOGIN_PATH])
      }
      localStorage.removeItem(LOCAL_STORAGE.REDIRECT_AFTER_LOGIN_PATH)
      localStorage.removeItem(LOCAL_STORAGE.REDIRECT_AFTER_LOGIN_TIME)
    }
  }, [isAuth, navigate])

  return isAuth ? <Outlet /> : <Navigate to={`/`} replace />
}
