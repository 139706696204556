import { ReactNode, useCallback, useMemo } from 'react'

import { BannerType } from 'config/banners'
import { SERVICES } from 'config/services'
import { BannersContext } from 'providers/Banners/BannersContext'
import { useFeathers } from 'providers/Feathers'

export const BannersProvider = ({ children }: { children: ReactNode }) => {
  const { client } = useFeathers()

  const bannersService = client.service(SERVICES.BANNERS)

  const findBanners = useCallback(
    (params?: object) => bannersService.find(params),
    [bannersService]
  )

  const getBanner = useCallback(
    (id: string, params?: object) => bannersService.get(id, params),
    [bannersService]
  )

  const createBanner = useCallback(
    (data: BannerType, params?: object) => bannersService.create(data, params),
    [bannersService]
  )

  const updateBanner = useCallback(
    (id: string, data: BannerType, params?: object) =>
      bannersService.patch(id, data, params),
    [bannersService]
  )

  const removeBanner = useCallback(
    (id: string, params?: object) => bannersService.remove(id, params),
    [bannersService]
  )

  const context = useMemo(
    () => ({
      bannersService,
      findBanners,
      getBanner,
      createBanner,
      updateBanner,
      removeBanner,
    }),
    [
      bannersService,
      findBanners,
      getBanner,
      createBanner,
      updateBanner,
      removeBanner,
    ]
  )

  return (
    <BannersContext.Provider value={context}>
      {children}
    </BannersContext.Provider>
  )
}
