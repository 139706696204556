import { LOCALES } from 'config/locale'

export const getLanguage = (locale: LOCALES): string => {
  return locale.substring(0, 2)
}

export const getCountry = (locale: LOCALES): string => {
  return locale.slice(2)
}

export const formatLocale = (locale: LOCALES, join: string) => {
  return getLanguage(locale) + join + getCountry(locale)
}
