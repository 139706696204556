import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { DEFAULT_HITS_PER_PAGE } from 'config/pagination'
import { SERVICES } from 'config/services'
import { FindSignaturesType, useSignatures } from 'providers/Signatures'
import { cleanArray } from 'utils'

export const useUserSignatureIds = (userId?: string) => {
  const { findSignatures } = useSignatures()

  const result = useQuery<FindSignaturesType>({
    enabled: Boolean(userId),
    queryKey: [SERVICES.SIGNATURES, userId],
    queryFn: () =>
      findSignatures({
        query: {
          userId,
          $sort: {
            createdAt: -1,
          },
          $limit: DEFAULT_HITS_PER_PAGE,
        },
      }),
  })

  return useMemo(
    () => ({
      ...result,
      userSignatureIds: cleanArray(
        result?.data?.data?.map(({ id }) => id) || []
      ),
      userSignatureParentSignatureIds: cleanArray(
        result?.data?.data?.map(({ parentSignatureId }) => parentSignatureId) ||
          []
      ),
      userSignaturesTotal: result?.data?.total,
    }),
    [result]
  )
}
