import React, { Dispatch, SetStateAction, useContext } from 'react'

interface IFilePreviewContext {
  filePreviewUrlIsOpen: boolean
  openFilePreviewUrl: Dispatch<SetStateAction<string | undefined>>
}

export const FilePreviewUrlContext = React.createContext<IFilePreviewContext>({
  filePreviewUrlIsOpen: false,
  openFilePreviewUrl: () => {},
})

export const useFilePreviewUrl = (): IFilePreviewContext =>
  useContext(FilePreviewUrlContext)
