import { lazy } from 'react'

import { ROUTES } from 'config/routes'
// Import direct theses files for intro pre-loading
import IntroLogo from 'routes/Flow/Content/IntroLogo'
import IntroPalettes from 'routes/Flow/Content/IntroPalettes'
import IntroTemplates from 'routes/Flow/Content/IntroTemplates'

const Details = lazy(() => import('routes/Flow/Content/Details'))
const Palettes = lazy(() => import('routes/Flow/Content/Palettes'))
const Templates = lazy(() => import('routes/Flow/Content/Templates'))
const Banners = lazy(() => import('routes/Flow/Content/Banners'))

export const FlowRoutes = [
  {
    path: `${ROUTES.INTRO_LOGO}`,
    element: <IntroLogo />,
  },
  {
    path: `${ROUTES.INTRO_PALETTES}`,
    element: <IntroPalettes />,
  },
  {
    path: `${ROUTES.INTRO_TEMPLATES}`,
    element: <IntroTemplates />,
  },
  {
    path: `${ROUTES.DETAILS}/:signatureId`,
    element: <Details />,
  },
  {
    path: `${ROUTES.PALETTES}/:signatureId`,
    element: <Palettes />,
  },
  {
    path: `${ROUTES.TEMPLATES}/:signatureId`,
    element: <Templates />,
  },
  {
    path: `${ROUTES.BANNERS}/:signatureId`,
    element: <Banners />,
  },
]
