import { DRAWERS } from 'config/drawers'
import { DRAWER_DEFAULT_TAB } from 'providers/Drawer/config'
import { DrawerInitType, DrawerType, TabType } from 'providers/Drawers'
import { cleanArray } from 'utils'

const SPLIT = '/'

export const formatDrawer = (
  drawer: DrawerType | DrawerInitType
): string | undefined => {
  const { name, id, tab = DRAWER_DEFAULT_TAB[name] } = drawer
  if (!name || !id || !tab) {
    return undefined
  }
  return `${name}${SPLIT}${id}${SPLIT}${tab}`
}

export const formatDrawers = (drawers: DrawerType[]): string[] =>
  cleanArray(drawers.map(formatDrawer))

export const parseDrawer = (string: string): DrawerType | undefined => {
  const [name, id, tab] = string.split(SPLIT)
  if (!(name in DRAWERS) || !id || !tab) {
    return
  }
  return { name: name as DRAWERS, id, tab: tab as TabType }
}

export const parseDrawers = (strings: string[]): DrawerType[] =>
  cleanArray(strings.map(parseDrawer))
