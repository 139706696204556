export enum LANGUAGES {
  English = 'en',
  French = 'fr',
}

export enum LOCALES {
  enGB = 'enGB',
  frFR = 'frFR',
}

export const DEFAULT_LOCALE = LOCALES.enGB

export const LANGUAGE_DEFAULT_LOCALE = {
  [LANGUAGES.English]: LOCALES.enGB,
  [LANGUAGES.French]: LOCALES.frFR,
}

export const LOCALE_ICON_FONT = {
  [LOCALES.enGB]: 'icon-united-kingdom',
  [LOCALES.frFR]: 'icon-france',
}
