import { createContext, useContext } from 'react'

import { GmailIntegrationType } from 'config/gmailIntegration'

interface IGmailIntegrationContext {
  GmailIntegrationService: any
  gmailIntegration: (
    data: GmailIntegrationType,
    params?: object
  ) => Promise<any>
}

export const GmailIntegrationContext = createContext<IGmailIntegrationContext>({
  GmailIntegrationService: {},
  gmailIntegration: () => Promise.resolve({}),
})

export const useGmailIntegration = (): IGmailIntegrationContext =>
  useContext(GmailIntegrationContext)
