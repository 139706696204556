// https://ant.design/components/image/

import { Image as AntdImage, ImageProps as AntdImageProps } from 'antd'
import { memo } from 'react'

export interface ImageProps extends AntdImageProps {}

const Image = ({
  alt = '',
  preview = false,
  fallback = '/assets/images/fallback.jpg',
  ...props
}: ImageProps) => {
  return (
    <AntdImage alt={alt} preview={preview} fallback={fallback} {...props} />
  )
}

export default memo(Image)
