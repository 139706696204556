import { createContext, useContext } from 'react'

import { FontType } from 'config/fonts'

export type FindFontsType = {
  limit: number
  skip: number
  total: number
  data: FontType[]
}

interface IFontsContext {
  fontsService: any
  findFonts: (params?: object) => Promise<FindFontsType>
  getFont: (id: string, params?: object) => Promise<FontType>
  createFont: (data: FontType, params?: object) => Promise<FontType>
  updateFont: (id: string, data: FontType, params?: object) => Promise<FontType>
  removeFont: (id: string, params?: object) => Promise<FontType>
}

export const FontsContext = createContext<IFontsContext>({
  fontsService: {},
  findFonts: () =>
    Promise.resolve({
      limit: 0,
      skip: 0,
      total: 0,
      data: [],
    }),
  getFont: () => Promise.resolve({}),
  createFont: () => Promise.resolve({}),
  updateFont: () => Promise.resolve({}),
  removeFont: () => Promise.resolve({}),
})

export const useFonts = (): IFontsContext => useContext(FontsContext)
