import { notification } from 'antd'
import { ArgsProps } from 'antd/es/notification/interface'
import React, { useContext } from 'react'

import { MessageType } from 'config/message'

export interface NotificationConfigType
  extends Omit<ArgsProps, 'message' | 'description'> {
  message: MessageType
  description?: MessageType
}

export interface INotificationContext {
  sendNotification: (type: string) => (options: NotificationConfigType) => void
  errorNotification: (options: NotificationConfigType) => void
  infoNotification: (options: NotificationConfigType) => void
  openNotification: (options: NotificationConfigType) => void
  successNotification: (options: NotificationConfigType) => void
  warningNotification: (options: NotificationConfigType) => void
  destroyNotification: () => void
}

export const NotificationContext = React.createContext<INotificationContext>({
  sendNotification: () => () => {},
  errorNotification: () => {},
  infoNotification: () => {},
  openNotification: () => {},
  successNotification: () => {},
  warningNotification: () => {},
  destroyNotification: () => {
    notification.destroy()
  },
})

export const useNotification = (): INotificationContext =>
  useContext(NotificationContext)
