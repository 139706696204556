import flatten from 'flat'
import { ReactNode, useEffect, useState } from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'

import { useLocale } from 'providers/Locale'

export const IntlProvider = ({ children }: { children: ReactNode }) => {
  const { formattedLocale } = useLocale()

  const [messages, setMessages] = useState<
    Record<string, Record<string, string>>
  >({})

  useEffect(() => {
    if (formattedLocale) {
      import(`lang/${formattedLocale}.json`)
        .then((json) => {
          setMessages((value) => ({
            ...value,
            [formattedLocale]: flatten(json.default),
          }))
        })
        .catch(console.error)
    }
  }, [formattedLocale])

  return (
    <ReactIntlProvider
      locale={formattedLocale}
      messages={messages[formattedLocale]}
    >
      {!messages[formattedLocale]}
      {children}
    </ReactIntlProvider>
  )
}
