import { createContext, useContext } from 'react'

import { SignatureType } from 'config/signatures'

export type FindSignaturesType = {
  limit: number
  skip: number
  total: number
  data: SignatureType[]
}

interface ISignaturesContext {
  signaturesService: any
  findSignatures: (params?: object) => Promise<FindSignaturesType>
  getSignature: (id: string, params?: object) => Promise<SignatureType>
  createSignature: (
    data: SignatureType,
    params?: object
  ) => Promise<SignatureType>
  updateSignature: (
    id: string,
    data: SignatureType,
    params?: object
  ) => Promise<SignatureType>
  removeSignature: (id: string, params?: object) => Promise<SignatureType>
}

export const SignaturesContext = createContext<ISignaturesContext>({
  signaturesService: {},
  findSignatures: () =>
    Promise.resolve({
      limit: 0,
      skip: 0,
      total: 0,
      data: [],
    }),
  getSignature: () => Promise.resolve({}),
  createSignature: () => Promise.resolve({}),
  updateSignature: () => Promise.resolve({}),
  removeSignature: () => Promise.resolve({}),
})

export const useSignatures = (): ISignaturesContext =>
  useContext(SignaturesContext)
