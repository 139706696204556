// https://ant.design/components/form/

import { Form, FormItemProps as AntdFormItemProps } from 'antd'
import { Rule } from 'rc-field-form/lib/interface'
import { memo, useCallback } from 'react'

import { MessageType } from 'config/message'
import { useFormatMessage } from 'hooks/message'

export type FormItemRuleType = Rule & {
  message?: MessageType
}
export interface FormItemProps
  extends Omit<
    AntdFormItemProps,
    'tooltip' | 'label' | 'extra' | 'help' | 'rules'
  > {
  tooltip?: MessageType
  label?: MessageType
  extra?: MessageType
  help?: MessageType
  rules?: FormItemRuleType[]
}

const FormItem = ({
  label,
  extra,
  rules,
  tooltip,
  help,
  ...rest
}: FormItemProps) => {
  const formatMessage = useFormatMessage()

  const formatRules = useCallback(
    (rules: any) => {
      return rules.map((rule: FormItemRuleType) => {
        if (typeof rule === 'function') {
          return rule
        }
        const { message, ...ruleRest } = rule
        return {
          ...ruleRest,
          message: message ? formatMessage(message) : undefined,
        }
      })
    },
    [formatMessage]
  )

  return (
    <Form.Item
      label={label ? formatMessage(label) : undefined}
      rules={rules ? formatRules(rules) : undefined}
      extra={extra ? formatMessage(extra) : undefined}
      tooltip={tooltip ? formatMessage(tooltip) : undefined}
      help={help ? formatMessage(help) : undefined}
      {...rest}
    />
  )
}

export default memo(FormItem)
