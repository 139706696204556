import {
  Colorfilter,
  Home2,
  MouseSquare,
  Personalcard,
  TickCircle,
  User,
} from 'iconsax-react'
import { memo } from 'react'
import { useNavigate, Link, useParams } from 'react-router-dom'

import Button from 'components/atoms/Button'
import Image from 'components/atoms/Image'
import Flex from 'components/atoms/Section/Flex'
import { ROUTES } from 'config/routes'
import { SLUGS } from 'config/slugs'
import { useSignatureParentSignatureId } from 'hooks/Signature/useSignatureParentSignatureId'
import { useWorkflows } from 'providers/Workflows'

function Header({ showMenu }: { showMenu: boolean }) {
  const navigate = useNavigate()
  const { startWorkflow } = useWorkflows()
  const { signatureId }: { signatureId?: string } = useParams()
  const { parentSignatureId } = useSignatureParentSignatureId(signatureId)

  return (
    <div
      className={`fixed left-0 right-0 top-0 z-10 flex max-h-16 items-center justify-center ${
        showMenu ? 'border-b' : ''
      } bg-white p-4`}
    >
      <div className="flex">
        <Image
          className="h-[34px] w-auto cursor-pointer"
          src={showMenu ? '/emailee-logo.svg' : '/emailee.svg'}
          onClick={() => navigate('/')}
        />
      </div>
      {showMenu ? (
        <>
          <div className="ml-2 rounded-lg border p-2 leading-[10px]">
            <Link to={'/'}>
              <Home2 size={22} />
            </Link>
          </div>
          {!parentSignatureId ? (
            <div className="p-2 leading-[10px]">
              <Link to={`/${ROUTES.DETAILS}/${signatureId}`}>
                <User size={22} />
              </Link>
            </div>
          ) : undefined}
          {!parentSignatureId ? (
            <div className="p-2 leading-[10px]">
              <Link to={`/${ROUTES.PALETTES}/${signatureId}`}>
                <Colorfilter size={22} />
              </Link>
            </div>
          ) : undefined}
          {!parentSignatureId ? (
            <div className="p-2 leading-[10px]">
              <Link to={`/${ROUTES.TEMPLATES}/${signatureId}`}>
                <Personalcard size={22} />
              </Link>
            </div>
          ) : undefined}
          {!parentSignatureId ? (
            <div className="p-2 leading-[10px]">
              <Link to={`/${ROUTES.BANNERS}/${signatureId}`}>
                <MouseSquare size={22} />
              </Link>
            </div>
          ) : undefined}
          <Flex />
          <Button
            className="ml-2"
            type="primary"
            message="word.doneEditing"
            size="large"
            icon={<TickCircle size={16} />}
            onClick={() => {
              startWorkflow({
                slug: SLUGS.SEE_GUIDE,
                data: {
                  signatureId,
                },
              })
            }}
          />
        </>
      ) : undefined}
    </div>
  )
}

export default memo(Header)
