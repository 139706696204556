// https://ant.design/components/alert/

import { Alert as AntdAlert, AlertProps as AntdAlertProps } from 'antd'
import { memo } from 'react'

import { MessageType } from '../../../config/message'
import { useFormatMessage } from '../../../hooks/message'

export interface AlertProps
  extends Omit<AntdAlertProps, 'message' | 'description'> {
  message?: MessageType
  description?: MessageType
}

const Alert = ({ message, description, ...rest }: AlertProps) => {
  const formatMessage = useFormatMessage()

  return (
    <AntdAlert
      message={message ? formatMessage(message) : undefined}
      description={description ? formatMessage(description) : undefined}
      {...rest}
    />
  )
}

export default memo(Alert)
