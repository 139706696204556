import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { PaletteType } from 'config/palettes'
import { SERVICES } from 'config/services'
import { usePalettes } from 'providers/Palettes'

export const usePaletteIsPremium = (paletteId?: string) => {
  const { getPalette } = usePalettes()

  const result = useQuery<PaletteType>({
    enabled: Boolean(paletteId),
    queryKey: [SERVICES.PALETTES, paletteId],
    queryFn: () => getPalette(paletteId as string),
  })

  return useMemo(
    () => ({
      ...result,
      isPremium: result?.data?.isPremium,
    }),
    [result]
  )
}
