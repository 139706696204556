import {
  UseMutationResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { AddCircle, CloseCircle, Edit } from 'iconsax-react'
import { memo, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from 'components/atoms/Button'
import Card from 'components/atoms/Card'
import ErrorOrDefault from 'components/atoms/ErrorAlert/ErrorOrDefault'
import Switch from 'components/atoms/Form/Switch'
import HelmetTitle from 'components/atoms/Helmet/Title'
import Image from 'components/atoms/Image'
import Message from 'components/atoms/Intl/Message'
import Modal from 'components/atoms/Modal'
import Flex from 'components/atoms/Section/Flex'
import Space from 'components/atoms/Space'
import Tag from 'components/atoms/Tag'
import TagProPlan from 'components/molecules/Tag/ProPlan'
import UserName from 'components/molecules/User/Name'
import { WorkflowModalDelete } from 'components/workflows/modalDelete'
import { DEFAULT_HITS_PER_PAGE } from 'config/pagination'
import { PaletteType } from 'config/palettes'
import { ROUTES } from 'config/routes'
import { SERVICES } from 'config/services'
import { SLUGS } from 'config/slugs'
import { UserType } from 'config/users'
import { usePaletteColors } from 'hooks/Palette/usePaletteColors'
import { usePaletteIsPremium } from 'hooks/Palette/usePaletteIsPremium'
import { usePaletteIsPublished } from 'hooks/Palette/usePaletteIsPublished'
import { usePaletteName } from 'hooks/Palette/usePaletteName'
import { usePaletteUserId } from 'hooks/Palette/usePaletteUserId'
import { useNotification } from 'providers/Notification'
import { FindPalettesType, usePalettes } from 'providers/Palettes'
import { usePricingTable } from 'providers/PricingTable'
import { useUser } from 'providers/User'
import { useUsers } from 'providers/Users'
import { useWorkflows } from 'providers/Workflows'

const CardPalette = memo(
  ({
    paletteId,
    update,
  }: {
    paletteId: string
    update: UseMutationResult<UserType, any, UserType, void>
  }) => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { startWorkflow } = useWorkflows()
    const { user, isAdmin, isFree } = useUser()
    const { setSeePricingTable } = usePricingTable()
    const { successNotification } = useNotification()
    const { getPalette, removePalette } = usePalettes()

    const { name } = usePaletteName(paletteId)
    const { userId } = usePaletteUserId(paletteId)
    const { colors = {} } = usePaletteColors(paletteId)
    const { isPremium } = usePaletteIsPremium(paletteId)
    const { isPublished } = usePaletteIsPublished(paletteId)

    const [openDelete, setOpenDelete] = useState<boolean | string>(false)

    const remove = useMutation({
      mutationKey: [SERVICES.PALETTES],
      mutationFn: (id: string) => removePalette(id),
      onSuccess: (data: PaletteType) => {
        queryClient.invalidateQueries({
          queryKey: [SERVICES.PALETTES],
        })
        successNotification({
          message: 'word.paletteSuccessfullyDeleted',
        })
      },
    })

    return (
      <>
        <Card
          styles={{
            body: {
              overflow: 'hidden',
              maxHeight: 'auto',
              height: 115,
              padding: 0,
            },
          }}
          className={`relative cursor-pointer overflow-hidden  border border-[#e5e3f6] bg-[#F9FAFB] transition hover:shadow-xl`}
          bordered
          hoverable={!(isPremium && isFree)}
          onClick={() => {
            if (isPremium && isFree) {
              setSeePricingTable(true)
            } else {
              update.mutate({
                // @ts-ignore
                paletteId,
              })
              navigate(`/${ROUTES.INTRO_TEMPLATES}`)
            }
          }}
          title={
            <div className="flex items-center">
              <div className="font-light">
                <Message value={name} />
              </div>
              <Flex />
              {isAdmin && !isPublished ? (
                <Tag
                  className="mr-0 rounded-full"
                  color="error"
                  message="word.unpublished"
                  style={{
                    padding: '0px 8px',
                    fontWeight: 'bold',
                    boxShadow:
                      '0px 3.12298px 18.73788px 0px rgba(130, 142, 171, 0.16)',
                  }}
                />
              ) : undefined}
              {isAdmin && userId ? (
                <Tag
                  className="mr-0 rounded-full"
                  color="error"
                  message={<UserName userId={userId} />}
                  style={{
                    padding: '0px 8px',
                    fontWeight: 'bold',
                    boxShadow:
                      '0px 3.12298px 18.73788px 0px rgba(130, 142, 171, 0.16)',
                  }}
                />
              ) : undefined}
              {isPremium && isFree ? (
                <TagProPlan className="absolute right-4 top-4 mr-0 rounded-full" />
              ) : undefined}
              {userId === user?.id ? (
                <>
                  <Button
                    overrideClassName={'h-[30px]'}
                    type="text"
                    key="edit"
                    icon={<Edit size={20} />}
                    onClick={(event) => {
                      event.stopPropagation()
                      event.preventDefault()
                      getPalette(paletteId).then((data) => {
                        startWorkflow({
                          slug: SLUGS.EDIT_PALETTE,
                          data,
                          onCompleted: () => {
                            queryClient.invalidateQueries({
                              queryKey: [SERVICES.PALETTES],
                            })
                          },
                        })
                      })
                    }}
                  />
                  <Button
                    overrideClassName={'h-[30px]'}
                    type="text"
                    shape="circle"
                    key="close"
                    icon={<CloseCircle size={20} />}
                    onClick={(event) => {
                      event.stopPropagation()
                      event.preventDefault()
                      setOpenDelete(paletteId)
                    }}
                  />
                </>
              ) : undefined}
            </div>
          }
        >
          <div className="flex h-full overflow-hidden">
            <div
              className={`delay h-full w-auto flex-1 transition-all duration-300 ${
                !isPremium || !isFree ? 'hover:flex-[2_2_0%]' : ''
              }`}
              style={{
                backgroundColor: colors['primary-default'] || '#ffffff',
              }}
              /* onClick={
                !isPremium || !isFree
                  ? async (event) => {
                      event.stopPropagation()
                      event.preventDefault()
                      await navigator.clipboard.writeText(
                        colors['primary-default']?.toLowerCase() || '#ffffff'
                      )
                      successNotification({
                        message: 'word.colorSuccessfullyCopiedToClipboard',
                      })
                    }
                  : undefined
              } */
            >
              <div
                className="flex h-full flex-1 items-center justify-center font-bold text-white opacity-0 transition-all hover:opacity-100"
                style={{
                  textShadow:
                    '-1px -1px 0 #111, 1px -1px 0 #111, -1px 1px 0 #111, 1px 1px 0 #111',
                }}
              >
                {colors['primary-default']?.toLowerCase() || '#ffffff'}
              </div>
            </div>
            <div
              className={`delay h-full w-auto flex-1 transition-all duration-300 ${
                !isPremium || !isFree ? 'hover:flex-[2_2_0%]' : ''
              }`}
              style={{
                backgroundColor: colors['primary-strong'] || '#ffffff',
              }}
              /* onClick={
                !isPremium || !isFree
                  ? async (event) => {
                      event.stopPropagation()
                      event.preventDefault()
                      await navigator.clipboard.writeText(
                        colors['primary-strong']?.toLowerCase() || '#ffffff'
                      )
                      successNotification({
                        message: 'word.colorSuccessfullyCopiedToClipboard',
                      })
                    }
                  : undefined
              } */
            >
              <div
                className="flex h-full flex-1 items-center justify-center font-bold text-white opacity-0 transition-all hover:opacity-100"
                style={{
                  textShadow:
                    '-1px -1px 0 #111, 1px -1px 0 #111, -1px 1px 0 #111, 1px 1px 0 #111',
                }}
              >
                {colors['primary-strong']?.toLowerCase() || '#ffffff'}
              </div>
            </div>
            <div
              className={`delay h-full w-auto flex-1 transition-all duration-300 ${
                !isPremium || !isFree ? 'hover:flex-[2_2_0%]' : ''
              }`}
              style={{
                backgroundColor: colors['accent-default'] || '#ffffff',
              }}
              /* onClick={
                !isPremium || !isFree
                  ? async (event) => {
                      event.stopPropagation()
                      event.preventDefault()
                      await navigator.clipboard.writeText(
                        colors['accent-default']?.toLowerCase() || '#ffffff'
                      )
                      successNotification({
                        message: 'word.colorSuccessfullyCopiedToClipboard',
                      })
                    }
                  : undefined
              } */
            >
              <div
                className="flex h-full flex-1 items-center justify-center font-bold text-white opacity-0 transition-all hover:opacity-100"
                style={{
                  textShadow:
                    '-1px -1px 0 #111, 1px -1px 0 #111, -1px 1px 0 #111, 1px 1px 0 #111',
                }}
              >
                {colors['accent-default']?.toLowerCase() || '#ffffff'}
              </div>
            </div>
            <div
              className={`delay h-full w-auto flex-1 transition-all duration-300 ${
                !isPremium || !isFree ? 'hover:flex-[2_2_0%]' : ''
              }`}
              style={{
                backgroundColor: colors['accent-strong'] || '#ffffff',
              }}
              /* onClick={
                !isPremium || !isFree
                  ? async (event) => {
                      event.stopPropagation()
                      event.preventDefault()
                      await navigator.clipboard.writeText(
                        colors['accent-strong']?.toLowerCase() || '#ffffff'
                      )
                      successNotification({
                        message: 'word.colorSuccessfullyCopiedToClipboard',
                      })
                    }
                  : undefined
              } */
            >
              <div
                className="flex h-full flex-1 items-center justify-center font-bold text-white opacity-0 transition-all hover:opacity-100"
                style={{
                  textShadow:
                    '-1px -1px 0 #111, 1px -1px 0 #111, -1px 1px 0 #111, 1px 1px 0 #111',
                }}
              >
                {colors['accent-strong']?.toLowerCase() || '#ffffff'}
              </div>
            </div>
          </div>
        </Card>
        <WorkflowModalDelete
          open={Boolean(openDelete)}
          onCancel={() => {
            setOpenDelete(false)
          }}
          submit={() => {
            remove.mutate(openDelete as string)
          }}
          title={'word.deletePalette'}
          button={'word.delete'}
        />
      </>
    )
  }
)

function IntroPalettes() {
  const navigate = useNavigate()
  const { updateUser } = useUsers()
  const queryClient = useQueryClient()
  const { findPalettes } = usePalettes()
  const { startWorkflow } = useWorkflows()
  const { user, isAdmin, isFree } = useUser()
  const { setSeePricingTable } = usePricingTable()

  const [onlyFree, setOnlyFree] = useState<boolean>(isFree)

  // const [nbHits, setNbHits] = useState<number>(0)
  const [hits, setHits] = useState<any[]>([])
  const [hitsPerPage, setHitsPerPage] = useState<number>(
    DEFAULT_HITS_PER_PAGE * 5
  )

  const [error, setError] = useState<null | string>(null)
  const [disabled, setDisabled] = useState<boolean>(false)

  // TODO: infinite scroll for colors

  useQuery<FindPalettesType>({
    queryKey: [SERVICES.PALETTES, { hitsPerPage, isAdmin, isFree, onlyFree }],
    queryFn: () =>
      findPalettes({
        query: {
          isPremium: onlyFree ? false : undefined,
          seeAsUser: isAdmin,
          $sort: {
            isPremium: isFree ? 1 : undefined,
            createdAt: -1,
          },
          $limit: hitsPerPage,
        },
      }),
    onSuccess: (result) => {
      if (result?.data?.length) {
        // setNbHits(result.total)
        setHits(result?.data)
      }
    },
  })

  const update = useMutation({
    mutationKey: [SERVICES.USERS, user?.id],
    mutationFn: (data: UserType) => updateUser(user?.id as string, data),
    onSuccess: (data: UserType) => {
      queryClient.invalidateQueries({ queryKey: [SERVICES.USERS, user?.id] })
    },
    onError: (error: any) => {
      return setError(error)
    },
    onMutate: () => setDisabled(true),
    onSettled: () => setDisabled(false),
  })

  const cardPalettes = useMemo(
    () =>
      hits?.map((hit) => (
        <CardPalette key={hit?.id} paletteId={hit?.id} update={update} />
      )) || [],
    [hits, update]
  )

  return (
    <div className="flex h-full w-full flex-col">
      <HelmetTitle title="word.chooseFavoriteColorPalette" />
      <div className="flex flex-row items-center justify-between px-4 pt-4 lg:px-16 lg:pt-16">
        <Space direction="vertical">
          <div className="text-3xl font-medium">
            <Message value="word.chooseFavoriteColorPalette" />
          </div>
        </Space>
        {isFree ? (
          <Space>
            <Switch value={onlyFree} onChange={setOnlyFree} />
            <div className="w-[150px]">
              <Message value="word.onlyFreePalettes" />
            </div>
          </Space>
        ) : undefined}
      </div>
      {error && (
        <div className="flex flex-col items-center px-4 pt-4 lg:px-16 lg:pt-16">
          <div className="w-full max-w-[620px]">
            <ErrorOrDefault error={error} afterClose={() => setError(null)} />
          </div>
        </div>
      )}
      <div className="my-4 grid h-full w-full grid-cols-2 gap-4 p-4 md:grid-cols-3 lg:grid-cols-4 lg:gap-8 lg:p-16">
        <Card
          className="relative border-dashed border-[#EBE9FC]"
          styles={{
            body: {
              height: '100%',
            },
          }}
          bordered
          hoverable
          onClick={() => {
            if (isFree) {
              setSeePricingTable(true)
            } else {
              startWorkflow({
                slug: SLUGS.EDIT_PALETTE,
                data: {
                  userId: user?.id,
                  colors: {
                    'primary-default': '#001AFF',
                    'primary-strong': '#080D3B',
                    'primary-light': '#99A3FF',
                    'primary-superlight': '#E5E8FF',
                    'primary-white': '#FFFFFF',
                    'accent-default': '#59D77C',
                    'accent-strong': '#0F1A12',
                    'accent-light': '#BDEFCB',
                    'accent-superlight': '#EEFBF2',
                    'accent-white': '#FAFAFA',
                  },
                },
                onCompleted: (data) => {
                  update.mutate({
                    // @ts-ignore
                    paletteId: data.id,
                  })
                  navigate(`/${ROUTES.INTRO_TEMPLATES}`)
                },
              })
            }
          }}
        >
          <div className="flex h-full flex-col items-center justify-center gap-4 lg:px-6">
            {isFree ? (
              <Image className="h-[22px]" src="/pro-feature.svg" />
            ) : undefined}
            <div className="text-center text-lg font-light">
              <Message
                value={isFree ? 'word.addPalette' : 'word.addColorPalette'}
              />
            </div>
            <div className="text-zinc-500">
              <AddCircle />
            </div>
          </div>
        </Card>
        {cardPalettes}
      </div>
    </div>
  )
}

export default memo(IntroPalettes)
