// https://ant.design/components/result/

import { Result as AntdResult, ResultProps as AntdResultProps } from 'antd'
import { memo } from 'react'

import { MessageType } from 'config/message'
import { useFormatMessage } from 'hooks/message'

interface ResultProps extends Omit<AntdResultProps, 'title' | 'subTitle'> {
  title?: MessageType
  subTitle?: MessageType
}

const Result = ({ title, subTitle, ...rest }: ResultProps) => {
  const formatMessage = useFormatMessage()

  return (
    <AntdResult
      title={title ? formatMessage(title) : undefined}
      subTitle={subTitle ? formatMessage(subTitle) : undefined}
      {...rest}
    />
  )
}

export default memo(Result)
