import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CloudPlus } from 'iconsax-react'
import { memo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ErrorOrDefault from 'components/atoms/ErrorAlert/ErrorOrDefault'
import Upload from 'components/atoms/Form/Upload'
import HelmetTitle from 'components/atoms/Helmet/Title'
import Message from 'components/atoms/Intl/Message'
import Space from 'components/atoms/Space'
import { LOGO_TYPES } from 'config/logos'
import { ROUTES } from 'config/routes'
import { SERVICES } from 'config/services'
import { UserType } from 'config/users'
import { useUser } from 'providers/User'
import { useUsers } from 'providers/Users'

function IntroLogo() {
  const { user } = useUser()
  const navigate = useNavigate()
  const { updateUser } = useUsers()
  const queryClient = useQueryClient()

  const [error, setError] = useState<null | string>(null)
  const [disabled, setDisabled] = useState<boolean>(false)

  const update = useMutation({
    mutationKey: [SERVICES.USERS, user?.id],
    mutationFn: (data: UserType) => updateUser(user?.id as string, data),
    onSuccess: (data: UserType) => {
      queryClient.invalidateQueries({ queryKey: [SERVICES.USERS, user?.id] })
      navigate(`/${ROUTES.INTRO_PALETTES}`)
    },
    onError: (error: any) => {
      return setError(error)
    },
    onMutate: () => setDisabled(true),
    onSettled: () => setDisabled(false),
  })

  return (
    <div className="flex h-full w-full flex-col">
      <HelmetTitle title="word.uploadYourLogo" />
      <div className="flex flex-col justify-between space-y-4 p-4 md:space-y-8 md:p-8 lg:space-y-16 lg:p-16">
        <Space direction="vertical">
          <div className="text-3xl font-medium">
            <Message
              value={{
                id: 'word.helloUploadYourLogo',
                values: {
                  firstName: user?.firstName || '',
                },
              }}
            />
          </div>
          <div className="text-sm font-light text-zinc-500">
            <Message value="word.pngFormatsAreBetter" />
          </div>
        </Space>
        {error && (
          <div className="flex flex-col items-center">
            <div className="w-full max-w-[620px]">
              <ErrorOrDefault error={error} afterClose={() => setError(null)} />
            </div>
          </div>
        )}
        <div className="flex flex-col items-center">
          <div className="h-[250px] w-full max-w-[620px]">
            <Upload
              showUploadList={false}
              className="h-full w-full"
              disabled={disabled}
              style={{
                backgroundColor: '#ffffff',
              }}
              isPublic
              onChange={(data) => {
                if (data[0]) {
                  update.mutate({
                    logoUrl: `https://${process.env.REACT_APP_AWS_S3_BUCKET_PUBLIC}.s3.amazonaws.com/${data[0]}`,
                    logoType: LOGO_TYPES.FULL,
                  })
                }
              }}
            >
              <p className="ant-upload-drag-icon">
                <CloudPlus className="inline" />
              </p>
              <p className="ant-upload-text">
                <Message value="word.clickToUpload" />
              </p>
              <p className="ant-upload-hint">
                <Message value="word.supportedPictureFormats" />
              </p>
            </Upload>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-4">
          <div
            className={`border-b border-b-black text-lg ${
              disabled ? '' : 'cursor-pointer'
            }`}
            onClick={() => {
              if (!disabled) {
                update.mutate({
                  logoType: LOGO_TYPES.NONE,
                })
              }
            }}
          >
            <Message value="word.iDoNotHaveALogo" />
          </div>
          <div className="max-w-[400px] text-center">
            <Message value="word.doNotWorryWeHaveTemplates" />
          </div>
        </div>
        {/* <Card
              bordered
              hoverable
              className="border-gray-300"
              onClick={() => {
                update.mutate({
                  logoType: LOGO_TYPES.LOGO,
                })
              }}
            >
              <Space direction="vertical" align="center" size="middle">
                <div>
                  <Image className="h-[35px] w-auto" src="/emailee-logo.svg" />
                </div>
                <div className="font-light text-2xl">
                  <Message value="An icon" />
                </div>
                <div className="font-light text-md text-center">
                  <Message value="An easy to remember shape at the centre of your logo design." />
                </div>
              </Space>
            </Card>
            <Card
              bordered
              hoverable
              className="border-gray-300"
              onClick={() => {
                update.mutate({
                  logoType: LOGO_TYPES.FULL,
                })
              }}
            >
              <Space direction="vertical" align="center" size="middle">
                <div>
                  <Image className="h-[35px] w-auto" src="/emailee.svg" />
                </div>
                <div className="font-light text-2xl">
                  <Message value="Icon with brand" />
                </div>
                <div className="font-light text-md text-center">
                  <Message value="An easy to remember shape at the centre of your logo design." />
                </div>
              </Space>
            </Card>
            <Card
              bordered
              hoverable
              className="border-gray-300"
              onClick={() => {
                update.mutate({
                  logoType: LOGO_TYPES.NAME,
                })
              }}
            >
              <Space direction="vertical" align="center" size="middle">
                <div>
                  <Image className="h-[35px] w-auto" src="/emailee-text.svg" />
                </div>
                <div className="font-light text-2xl">
                  <Message value="A name" />
                </div>
                <div className="font-light text-md text-center">
                  <Message value="An easy to remember shape at the centre of your logo design." />
                </div>
              </Space>
            </Card> */}
      </div>
    </div>
  )
}

export default memo(IntroLogo)
