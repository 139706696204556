import React, { useContext } from 'react'

interface IFeathersContext {
  client: any
}

export const FeathersContext = React.createContext<IFeathersContext>({
  client: null,
})

export const useFeathers = (): IFeathersContext => useContext(FeathersContext)
