import NP from 'number-precision'

import { cleanArray } from 'utils'

export type RatioType = {
  numerator: number
  denominator: number
}

export const isNumber = (value: any): value is number | string => {
  return (
    ['string', 'number'].includes(typeof value) &&
    value !== '' &&
    !isNaN(Number(value))
  )
}

export const isRatio = (value: any): value is string => {
  if (isNumber(value)) {
    return true
  }

  const [numerator, denominator, ...rest] = String(value).split('/')

  return (
    isNumber(numerator) &&
    isNumber(denominator) &&
    denominator !== '0' &&
    rest.length === 0
  )
}

export const parseNumber = (value: string): number | undefined => {
  if (isNumber(value)) {
    return Number(value)
  }

  return undefined
}

export const getRatioValue = (ratio?: any): number | undefined => {
  if (isNumber(ratio)) {
    return Number(ratio)
  }

  if (typeof ratio === 'string') {
    const [numerator, denominator, ...rest] = ratio.split('/')

    if (
      isNumber(numerator) &&
      isNumber(denominator) &&
      denominator !== '0' &&
      rest.length === 0
    ) {
      return NP.divide(Number(numerator), Number(denominator))
    }
  }

  if (
    typeof ratio?.numerator === 'number' &&
    typeof ratio?.denominator === 'number' &&
    ratio.denominator !== 0
  ) {
    return NP.divide(ratio.numerator, ratio.denominator)
  }

  return undefined
}

export const multiplyRatio = (
  ratio: string | number,
  value: number
): number | undefined => {
  if (typeof ratio === 'string') {
    const [numerator, denominator, ...rest] = ratio.split('/')

    if (
      isNumber(numerator) &&
      isNumber(denominator) &&
      denominator !== '0' &&
      rest.length === 0
    ) {
      return NP.divide(NP.times(Number(numerator), value), Number(denominator))
    }
  }

  if (isNumber(ratio)) {
    return Number(ratio) * value
  }

  return undefined
}

export const sumRatio = (...args: string[]): string | undefined => {
  const formattedArgs: string[] = cleanArray(
    args.map((arg) => {
      if (isNumber(arg)) {
        return `${arg}/1`
      }
      if (isRatio(arg)) {
        return arg
      }

      return null
    })
  )

  if (!formattedArgs.length) {
    return undefined
  }

  const maxDenominator: number = formattedArgs.reduce((acc, cur) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, denominator] = cur.split('/')
    return +denominator > acc ? +denominator : acc
  }, 1)

  const value: string = formattedArgs.reduce((acc, cur) => {
    const [curNumerator, curDenominator] = cur.split('/')
    const [accNumerator, accDenominator] = acc.split('/')

    return `${NP.plus(
      NP.times(+curNumerator, NP.divide(maxDenominator, +curDenominator)),
      NP.times(+accNumerator, NP.divide(maxDenominator, +accDenominator))
    )}/${maxDenominator}`
  }, '0/1')

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [numerator, _] = value.split('/')

  if (numerator) return value
}

export const diffRatio = (...args: string[]): string | undefined => {
  const formattedArgs: string[] = cleanArray(
    args.map((arg) => {
      if (isNumber(arg)) {
        return `${arg}/1`
      }
      if (isRatio(arg)) {
        return arg
      }

      return null
    })
  )

  if (!formattedArgs.length) {
    return undefined
  }

  const maxDenominator: number = formattedArgs.reduce((acc, cur) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, denominator] = cur.split('/')
    return +denominator > acc ? +denominator : acc
  }, 1)

  const value: string = formattedArgs.reduce((acc, cur) => {
    const [curNumerator, curDenominator] = cur.split('/')
    const [accNumerator, accDenominator] = acc.split('/')

    return `${NP.minus(
      NP.times(+curNumerator, NP.divide(maxDenominator, +curDenominator)),
      NP.times(+accNumerator, NP.divide(maxDenominator, +accDenominator))
    )}/${maxDenominator}`
  }, '0/1')

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [numerator, _] = value.split('/')

  if (numerator) return value
}
