// https://ant.design/components/card/

import { Card as AntdCard } from 'antd'
import { CardProps as AntdCardProps, CardTabListType } from 'antd/lib/card'
import { memo, ElementType, Suspense, useMemo, useState } from 'react'

import { MessageType } from 'config/message'
import { useFormatMessage } from 'hooks/message'
import { cleanArray } from 'utils'

export type TabType = CardTabListType & {
  component: ElementType
  componentProps?: Record<string, any>
}
export interface CardProps extends Omit<AntdCardProps, 'title'> {
  title?: MessageType
  tabs?: (TabType | undefined)[]
}

const Card = ({
  title,
  tabs,
  activeTabKey,
  defaultActiveTabKey,
  children,
  ...rest
}: CardProps) => {
  const formatMessage = useFormatMessage()
  const [currentTab, setCurrentTab] = useState(
    activeTabKey || defaultActiveTabKey || tabs?.[0]?.key
  )

  const TabComponent = useMemo(() => {
    return tabs?.find((tab) => tab?.key === currentTab)?.component
  }, [tabs, currentTab])

  const tabComponentProps = useMemo(() => {
    return tabs?.find((tab) => tab?.key === currentTab)?.componentProps || {}
  }, [tabs, currentTab])

  return (
    <AntdCard
      title={title ? formatMessage(title) : undefined}
      tabList={
        tabs
          ? cleanArray(tabs).map(({ key, tab }) => ({
              key,
              tab: formatMessage(tab as string),
            }))
          : undefined
      }
      activeTabKey={activeTabKey || currentTab}
      defaultActiveTabKey={defaultActiveTabKey}
      onTabChange={tabs ? setCurrentTab : undefined}
      {...rest}
    >
      {TabComponent ? (
        <Suspense fallback={null}>
          <TabComponent {...tabComponentProps} />
        </Suspense>
      ) : (
        children
      )}
    </AntdCard>
  )
}

export default memo(Card)
