import { createContext, useContext } from 'react'

interface IIntercomContext {
  hide: () => void
  show: () => void
}

export const IntercomContext = createContext<IIntercomContext>({
  hide: () => {},
  show: () => {},
})

export const useIntercom = (): IIntercomContext => useContext(IntercomContext)
