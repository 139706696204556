// https://ant.design/components/checkbox/

import {
  Checkbox as AntdCheckbox,
  CheckboxProps as AntdCheckboxProps,
} from 'antd'
import { memo } from 'react'

import { MessageType } from 'config/message'
import { useFormatMessage } from 'hooks/message'
import { isSet } from 'utils'

export interface CheckboxProps extends Omit<AntdCheckboxProps, 'onChange'> {
  message?: MessageType
  onChange?: (value: boolean) => void
  myRef?: any
}

const Checkbox = ({
  message,
  value,
  onChange = () => {},
  children,
  myRef,
  ...rest
}: CheckboxProps) => {
  const formatMessage = useFormatMessage()

  return (
    <AntdCheckbox
      ref={myRef}
      {...(isSet(value) ? { checked: value } : {})}
      onChange={(event) => onChange(event?.target?.checked)}
      {...rest}
    >
      {children || formatMessage(message)}
    </AntdCheckbox>
  )
}

export default memo(Checkbox)
