import { createContext, useContext } from 'react'

import { SLUGS } from 'config/slugs'
import { WorkflowType } from 'providers/Workflows/WorkflowContext'

export interface StartWorkflowOptionsType {
  slug: SLUGS
  data?: Record<string, any>
  onCompleted?: (data?: any) => void
}

interface IWorkflowsContext {
  workflows: WorkflowType[]
  startWorkflow: (options: StartWorkflowOptionsType) => void
}

export const WorkflowsContext = createContext<IWorkflowsContext>({
  workflows: [],
  startWorkflow: () => {},
})

export const useWorkflows = (): IWorkflowsContext =>
  useContext(WorkflowsContext)
