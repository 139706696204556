import { Trash } from 'iconsax-react'
import { ReactNode, useEffect, useMemo, useState } from 'react'

import Alert, { AlertProps } from 'components/atoms/Alert'
import Button from 'components/atoms/Button'
import Input from 'components/atoms/Form/Input'
import Label from 'components/atoms/Form/Label'
import HelmetTitle from 'components/atoms/Helmet/Title'
import Modal, { ModalProps } from 'components/atoms/Modal'
import Flex from 'components/atoms/Section/Flex'
import Space from 'components/atoms/Space'
import { MessageType } from 'config/message'
import { useFormatMessage } from 'hooks/message'
import { useUser } from 'providers/User'
import { sanitize } from 'utils'
import { isDevEnv } from 'utils/env'

export const WorkflowModalDelete = ({
  icon = <Trash size={16} />,
  onCancel = () => {},
  withInput = true,
  withAlert = true,
  wordToWrite,
  alert = {},
  content,
  submit,
  button = 'word.delete',
  open,
  title,
  ...rest
}: ModalProps & {
  icon?: ReactNode
  content?: ReactNode
  button?: MessageType
  wordToWrite?: string
  withInput?: boolean
  withAlert?: boolean
  alert?: AlertProps
  submit?: any
}) => {
  const { isAdmin } = useUser()
  const formatMessage = useFormatMessage()
  const word = useMemo(
    () => wordToWrite || (formatMessage(button || title) as string),
    [formatMessage, wordToWrite, button, title]
  )
  const [inputValue, setInputValue] = useState<string | null>(
    isDevEnv || isAdmin ? word : null
  )

  useEffect(() => {
    if (word) {
      setInputValue(word)
    }
  }, [word])

  return (
    <Modal
      centered
      destroyOnClose
      open={open}
      onOk={submit}
      onCancel={onCancel}
      title={
        title || {
          id: 'deleteVerification.label',
          values: { word },
        }
      }
      footer={
        <div className="flex">
          <Flex />
          <Space>
            <Button
              className="flex flex-row items-center p-5"
              key="close"
              // @ts-ignore
              onClick={onCancel}
              message="word.close"
            />
            <Button
              className="flex flex-row items-center p-5"
              danger
              key="delete"
              type="primary"
              htmlType="button"
              disabled={
                withInput &&
                (!inputValue || sanitize(inputValue) !== sanitize(word))
              }
              icon={icon}
              message={button || title}
              onClick={(e) => {
                submit()
                // @ts-ignore
                onCancel(e)
              }}
            />
          </Space>
        </div>
      }
      {...rest}
    >
      <HelmetTitle title={word} />
      <Space direction="vertical" className="space-y-3 py-6">
        {withAlert && (
          <Alert
            showIcon
            type="warning"
            message="deleteVerification.alert"
            {...alert}
          />
        )}
        {content}
        {withInput ? (
          <Label
            label={
              title
                ? {
                    id: 'deleteVerification.label',
                    values: { word },
                  }
                : undefined
            }
          >
            <Input
              placeholder={button || title}
              value={inputValue}
              onChange={setInputValue}
            />
          </Label>
        ) : undefined}
      </Space>
    </Modal>
  )
}
