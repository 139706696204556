import { ReactNode, useCallback, useMemo } from 'react'

import { SERVICES } from 'config/services'
import { UserType } from 'config/users'
import { useFeathers } from 'providers/Feathers'
import { useUser } from 'providers/User/UserContext'
import { UsersContext } from 'providers/Users/UsersContext'

export const UsersProvider = ({ children }: { children: ReactNode }) => {
  const { user, setUser } = useUser()
  const { client } = useFeathers()

  const usersService = client.service(SERVICES.USERS)

  const findUsers = useCallback(
    (params?: object) => usersService.find(params),
    [usersService]
  )

  const getUser = useCallback(
    (id: string, params?: object) => usersService.get(id, params),
    [usersService]
  )

  const createUser = useCallback(
    (data: UserType, params?: object) => usersService.create(data, params),
    [usersService]
  )

  const updateUser = useCallback(
    (id: string, data: UserType, params?: object) =>
      usersService.patch(id, data, params).then((data: UserType) => {
        if (data?.id === user?.id) {
          setUser((user) => ({ ...user, ...data }))
        }
      }),
    [usersService, user, setUser]
  )

  const removeUser = useCallback(
    (id: string, params?: object) => usersService.remove(id, params),
    [usersService]
  )

  const context = useMemo(
    () => ({
      usersService,
      findUsers,
      getUser,
      createUser,
      updateUser,
      removeUser,
    }),
    [usersService, findUsers, getUser, createUser, updateUser, removeUser]
  )

  return (
    <UsersContext.Provider value={context}>{children}</UsersContext.Provider>
  )
}
