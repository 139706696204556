import { BrowserTracing } from '@sentry/browser'
import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import weekday from 'dayjs/plugin/weekday'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'

import 'styles/general.scss'
import 'styles/fonts.scss'

import App from 'App'
import { ENV, NODE_ENV } from 'config/env'
import reportWebVitals from 'reportWebVitals'
import { isProdEnv } from 'utils/env'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

Sentry.init({
  enabled: isProdEnv,
  environment: NODE_ENV || ENV.DEV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  tracesSampleRate: isProdEnv ? 0.2 : 1.0,
  integrations: [new BrowserTracing()],
  attachStacktrace: true,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <StrictMode>
    <App />
  </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
