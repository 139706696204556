import { memo } from 'react'
import { Outlet } from 'react-router-dom'

import Header from 'routes/Home/Header'
import Sider from 'routes/Home/Sider'

function Home() {
  return (
    <div className="disable-scrollbars flex antialiased">
      <div className="block lg:hidden">
        <Header />
      </div>
      <div className="hidden lg:block">
        <Sider />
      </div>
      <div className="mt-[64px] flex min-h-screen flex-1 flex-col lg:ml-[250px] lg:mt-0">
        <Outlet />
      </div>
    </div>
  )
}

export default memo(Home)
