import chroma from 'chroma-js'
import { lch } from 'd3-color'

import { hashStringToInt } from 'utils'

export const hashStringToColor = (string: string) => {
  const hue = hashStringToInt(string, { max: 360, min: 0 })

  return lch(60, 80, hue).formatHex()
}

export const colorRange = (
  length: number,
  color: string[] = ['#B1CEFF', '#1F4B99']
) => {
  const scale = chroma.scale(color).mode('lab')

  return Array(length)
    .fill(0)
    .map((_, i) => scale(i / length).hex())
}

const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        blue: parseInt(result[3], 16),
        green: parseInt(result[2], 16),
        red: parseInt(result[1], 16),
      }
    : null
}

export const colorForBackground = (backgroundColor: string) => {
  const rgb = hexToRgb(backgroundColor)
  if (rgb && rgb.red * 0.299 + rgb.green * 0.587 + rgb.blue * 0.114 > 186) {
    return 'dark'
  }
  return 'light'
}

export const shadeColor = (color: string, percent: number) => {
  let R = parseInt(color.substring(1, 3), 16)
  let G = parseInt(color.substring(3, 5), 16)
  let B = parseInt(color.substring(5, 7), 16)

  // @ts-ignore
  R = parseInt((R * (100 + percent)) / 100)
  // @ts-ignore
  G = parseInt((G * (100 + percent)) / 100)
  // @ts-ignore
  B = parseInt((B * (100 + percent)) / 100)

  R = R < 255 ? R : 255
  G = G < 255 ? G : 255
  B = B < 255 ? B : 255

  R = Math.round(R)
  G = Math.round(G)
  B = Math.round(B)

  const RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16)
  const GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16)
  const BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16)

  return '#' + RR + GG + BB
}
