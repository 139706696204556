// https://ant.design/components/upload/

import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Upload as AntdUpload, UploadProps as AntdUploadProps } from 'antd'
import { RcFile, UploadFile } from 'antd/es/upload'
import ImgCrop from 'antd-img-crop'
import axios from 'axios'
import { set } from 'lodash'
import { isArray } from 'lodash/fp'
import { memo, ReactNode, useState } from 'react'

import Image from 'components/atoms/Image'
import Modal from 'components/atoms/Modal'
import { LOCAL_STORAGE } from 'config/localStorage'
import { SERVICES } from 'config/services'
import { useFormatMessage } from 'hooks/message'
import { useNotification } from 'providers/Notification'

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })
interface DragableUploadListItemProps {
  originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  file: UploadFile
  fileList: any[]
}

const DraggableUploadListItem = ({
  originNode,
  fileList,
  file,
}: DragableUploadListItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: file.uid,
  })

  return (
    <div
      ref={setNodeRef}
      className={`h-[75px] w-[82px] overflow-hidden ${
        isDragging ? 'pointer-events-none	' : ''
      }`}
      style={{
        cursor: fileList?.length > 1 ? 'grab' : 'default',
        transform: CSS.Transform.toString(transform),
        transition,
      }}
      {...attributes}
      {...listeners}
    >
      {/* hide error tooltip when dragging */}
      {file.status === 'error' && isDragging
        ? originNode.props.children
        : originNode}
    </div>
  )
}
export interface UploadProps extends Omit<AntdUploadProps, 'onChange'> {
  isPublic?: boolean
  children: ReactNode
  onChange?: (files: any[]) => void
  value?: string[] | string
}

const UploadComponent = ({
  isPublic = false,
  multiple = false,
  onChange = () => {},
  accept = '.png, .gif, .jpg, .jpeg', // .svg
  value,
  ...rest
}: UploadProps) => {
  const { infoNotification } = useNotification()

  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')

  const [fileList, setFileList] = useState<UploadFile<RcFile>[]>(
    multiple
      ? isArray(value)
        ? value?.map((v) => ({
            uid: v,
            name: v?.split('/')?.at(-1) || v,
            status: 'done',
            url: v,
          }))
        : []
      : value && !isArray(value)
      ? [
          {
            uid: value,
            name: value?.split('/')?.at(-1) || value,
            status: 'done',
            url: value,
          },
        ]
      : []
  )

  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  })

  // TODO: fix drag and drop feature it's seems not working now
  const onDragEnd = ({ active, over }: any) => {
    if (active.id !== over?.id) {
      setFileList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id)
        const overIndex = prev.findIndex((i) => i.uid === over?.id)
        return arrayMove(prev, activeIndex, overIndex)
      })
    }
  }

  return (
    <>
      <DndContext
        sensors={[sensor]}
        onDragEnd={onDragEnd}
        cancelDrop={() => !multiple}
      >
        <SortableContext
          disabled={!multiple || fileList?.length < 2}
          items={fileList?.map((i) => i.uid) || []}
          strategy={verticalListSortingStrategy}
        >
          <AntdUpload.Dragger
            multiple={multiple}
            maxCount={multiple ? 30 : 1}
            accept={accept}
            listType={
              accept?.includes('pdf') || !multiple ? 'picture-card' : 'picture'
            }
            beforeUpload={(file: RcFile) => {
              const isLt2M = file.size / 1024 / 1024 < 2
              const isMimeTypePicture = [
                // 'image/svg+xml',
                'image/png',
                'image/gif',
                'image/jpg',
                'image/jpeg',
              ].includes(file.type)
              if (!isMimeTypePicture || !isLt2M) {
                infoNotification({
                  message: 'word.imageDoesNotMeetTheFollowingCriteria',
                })
              }
              return isMimeTypePicture && isLt2M ? true : AntdUpload.LIST_IGNORE
            }}
            showUploadList={{
              showRemoveIcon: true,
              showPreviewIcon: false,
              showDownloadIcon: false,
            }}
            itemRender={(originNode, file) =>
              multiple ? (
                <DraggableUploadListItem
                  originNode={originNode}
                  fileList={fileList}
                  file={file}
                />
              ) : undefined
            }
            fileList={fileList}
            onChange={({ fileList }) => {
              setFileList(fileList)
              onChange(
                fileList?.map((file) => file?.response?.id || file?.name)
              )
            }}
            onPreview={async (file: UploadFile) => {
              // TODO: make it works for pdf too
              if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj as RcFile)
              }

              setPreviewImage(file.url || (file.preview as string))
              setPreviewOpen(true)
            }}
            customRequest={async ({
              file,
              // onProgress = () => {},
              onSuccess = () => {},
              onError = () => {},
            }) => {
              const form = new FormData()
              form.append('uri', file)
              // @ts-ignore
              form.append('name', file?.name as string)
              // @ts-ignore
              form.append('type', file?.type as string)

              const headers = { 'Content-Type': 'multipart/form-data' }
              if (localStorage.getItem(LOCAL_STORAGE.TOKEN_ID)) {
                set(
                  headers,
                  'Authorization',
                  `Bearer ${localStorage.getItem(LOCAL_STORAGE.TOKEN_ID)}`
                )
              }
              // TODO: Change for redaxios package
              await axios
                .post(
                  `${process.env.REACT_APP_API_ENDPOINT}/${
                    isPublic ? SERVICES.UPLOAD_PUBLIC : SERVICES.UPLOAD
                  }`,
                  form,
                  {
                    method: 'post',
                    headers,
                    /* onUploadProgress: (event: any) => {
                      onProgress({
                        percent: (event.loaded / event.total) * 100,
                      })
                    }, */
                  }
                )
                .then(({ data }) => {
                  onSuccess(data)
                })
                .catch((error) => {
                  onError(error)
                })
            }}
            {...rest}
          />
        </SortableContext>
      </DndContext>
      <Modal
        closable={false}
        open={previewOpen}
        title={null}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
      >
        <Image style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  )
}

const Upload = ({
  withCrop,
  ...rest
}: UploadProps & { withCrop?: boolean }) => {
  const formatMessage = useFormatMessage()

  return withCrop ? (
    <ImgCrop
      quality={1}
      aspectSlider
      rotationSlider
      fillColor="transparent"
      showReset
      resetText={formatMessage('word.reset')}
      modalOk={formatMessage('word.confirm')}
      modalCancel={formatMessage('word.cancel')}
      modalTitle={formatMessage('word.cropImage')}
    >
      <UploadComponent {...rest} />
    </ImgCrop>
  ) : (
    <UploadComponent {...rest} />
  )
}

export default memo(Upload)
