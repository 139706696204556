import {
  Buildings,
  Chart2,
  Colorfilter,
  Home2,
  LinkSquare,
  MouseSquare,
  Notepad2,
  People,
  Personalcard,
  Profile2User,
  User,
} from 'iconsax-react'
import { memo } from 'react'
import { useNavigate, Link } from 'react-router-dom'

import Divider from 'components/atoms/Divider'
import Image from 'components/atoms/Image'
import Flex from 'components/atoms/Section/Flex'
import Tooltip from 'components/atoms/Tooltip'
import UserAvatar from 'components/molecules/User/Avatar'
import { LOCALES } from 'config/locale'
import { ROUTES } from 'config/routes'
import { USER_ROLES } from 'config/users'
import { useLocale } from 'providers/Locale'
import { useUser } from 'providers/User'

function Header() {
  const navigate = useNavigate()
  const { locale, setLocale } = useLocale()
  const { user, isAdmin, isRoleIn } = useUser()

  return (
    <div className="fixed top-0 z-10 flex h-16 w-full items-center justify-center border-b bg-white px-4 py-2">
      <div className="flex">
        <Image
          className="h-[34px] w-auto cursor-pointer"
          src="/emailee-logo.svg"
          onClick={() => navigate('/')}
        />
      </div>
      <div className="ml-2 rounded-lg border p-2 leading-[10px]">
        <Tooltip title="word.mySignatures">
          <Link to={'/'}>
            <Home2 size={22} />
          </Link>
        </Tooltip>
      </div>
      {isRoleIn([USER_ROLES.ADMIN, USER_ROLES.OWNER, USER_ROLES.MANAGER]) &&
      user?.companyId ? (
        <div className="p-2 leading-[10px]">
          <Tooltip title="word.users">
            <Link to={`/${ROUTES.USERS}`}>
              <User size={22} />
            </Link>
          </Tooltip>
        </div>
      ) : undefined}
      {isRoleIn([USER_ROLES.ADMIN, USER_ROLES.OWNER, USER_ROLES.MANAGER]) &&
      user?.companyId ? (
        <div className="p-2 leading-[10px]">
          <Tooltip title="word.teams">
            <Link to={`/${ROUTES.TEAMS}`}>
              <People size={22} />
            </Link>
          </Tooltip>
        </div>
      ) : undefined}
      {/* <div className="p-2 leading-[10px]">
            <Tooltip title="word.analytics">
              <Link to={`/${ROUTES.ANALYTICS}`}>
                <Chart2 size={22} />
              </Link>
            </Tooltip>
          </div> */}
      {isAdmin ? (
        <>
          {user?.companyId ? <Divider type="vertical" /> : undefined}
          {/* <div className="p-2 leading-[10px]">
            <Tooltip title="word.analytics">
              <Link to={`/${ROUTES.ADMIN_ANALYTICS}`}>
                <Chart2 size={22} />
              </Link>
            </Tooltip>
          </div> */}
          <div className="p-2 leading-[10px]">
            <Tooltip title="word.companies">
              <Link to={`/${ROUTES.ADMIN_COMPANIES}`}>
                <Buildings size={22} />
              </Link>
            </Tooltip>
          </div>
          <div className="p-2 leading-[10px]">
            <Tooltip title="word.users">
              <Link to={`/${ROUTES.ADMIN_USERS}`}>
                <Profile2User size={22} />
              </Link>
            </Tooltip>
          </div>
          <div className="p-2 leading-[10px]">
            <Tooltip title="word.teams">
              <Link to={`/${ROUTES.ADMIN_TEAMS}`}>
                <People size={22} />
              </Link>
            </Tooltip>
          </div>
          <div className="p-2 leading-[10px]">
            <Tooltip title="word.signatures">
              <Link to={`/${ROUTES.ADMIN_SIGNATURES}`}>
                <Notepad2 size={22} />
              </Link>
            </Tooltip>
          </div>
          <div className="p-2 leading-[10px]">
            <Tooltip title="word.signatureBanners">
              <Link to={`/${ROUTES.ADMIN_SIGNATURE_BANNERS}`}>
                <LinkSquare size={22} />
              </Link>
            </Tooltip>
          </div>
          <div className="p-2 leading-[10px]">
            <Tooltip title="word.palettes">
              <Link to={`/${ROUTES.ADMIN_PALETTES}`}>
                <Colorfilter size={22} />
              </Link>
            </Tooltip>
          </div>
          <div className="p-2 leading-[10px]">
            <Tooltip title="word.templates">
              <Link to={`/${ROUTES.ADMIN_TEMPLATES}`}>
                <Personalcard size={22} />
              </Link>
            </Tooltip>
          </div>
          <div className="p-2 leading-[10px]">
            <Tooltip title="word.banners">
              <Link to={`/${ROUTES.ADMIN_BANNERS}`}>
                <MouseSquare size={22} />
              </Link>
            </Tooltip>
          </div>
        </>
      ) : undefined}
      <Flex />
      <div>
        <button
          className="flex h-8 w-12 cursor-pointer items-center justify-center bg-transparent"
          onClick={() => {
            setLocale(locale === LOCALES.enGB ? LOCALES.frFR : LOCALES.enGB)
          }}
        >
          <span
            className={`fi ${
              locale === LOCALES.enGB ? 'fi-fr' : 'fi-gb'
            } text-base`}
          ></span>
        </button>
      </div>
      <div>
        <div className="relative h-8 w-8">
          <Link to={`/${ROUTES.PROFILE}`}>
            <UserAvatar userId={user?.id} />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default memo(Header)
