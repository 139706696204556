import Intercom, { hide, show } from '@intercom/messenger-js-sdk'
import { ReactNode } from 'react'

import { useUser } from 'providers/User'
import { getTimestamp } from 'utils/date'

import { IntercomContext } from './IntercomContext'

export const IntercomProvider = ({ children }: { children: ReactNode }) => {
  const { user, isAuth } = useUser()

  if (isAuth && user?.user_hash) {
    Intercom({
      app_id: process.env.REACT_APP_INTERCOM_APP_ID as string,
      user_id: user?.id,
      name: user?.firstName
        ? `${user?.firstName} ${user?.lastName}`
        : undefined,
      email: user?.email || undefined,
      avatar: user?.pictureUrl || undefined,
      created_at: getTimestamp(user?.createdAt || '') || undefined,
      user_hash: user?.user_hash || undefined,
    })
  }

  return (
    <IntercomContext.Provider value={{ hide, show }}>
      {children}
    </IntercomContext.Provider>
  )
}
