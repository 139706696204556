import { createContext, useContext } from 'react'

import { TeamType } from 'config/teams'

export type FindTeamsType = {
  limit: number
  skip: number
  total: number
  data: TeamType[]
}

interface ITeamsContext {
  teamsService: any
  findTeams: (params?: object) => Promise<FindTeamsType>
  getTeam: (id: string, params?: object) => Promise<TeamType>
  createTeam: (data: TeamType, params?: object) => Promise<TeamType>
  updateTeam: (id: string, data: TeamType, params?: object) => Promise<TeamType>
  removeTeam: (id: string, params?: object) => Promise<TeamType>
}

export const TeamsContext = createContext<ITeamsContext>({
  teamsService: {},
  findTeams: () =>
    Promise.resolve({
      limit: 0,
      skip: 0,
      total: 0,
      data: [],
    }),
  getTeam: () => Promise.resolve({}),
  createTeam: () => Promise.resolve({}),
  updateTeam: () => Promise.resolve({}),
  removeTeam: () => Promise.resolve({}),
})

export const useTeams = (): ITeamsContext => useContext(TeamsContext)
