// https://ant.design/components/avatar/

import { Avatar as AntdAvatar, AvatarProps as AntdAvatarProps } from 'antd'
import { memo } from 'react'

export interface AvatarProps extends AntdAvatarProps {}

const Avatar = (props: AvatarProps) => {
  return <AntdAvatar {...props} />
}

export default memo(Avatar)
