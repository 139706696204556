import { createContext, ElementType, useContext } from 'react'

export interface WorkflowType<T = Record<string, any>> {
  data?: T
  component: ElementType
  onCompleted?: (data?: any) => void
}

export interface IWorkflowContext<T = Record<string, any>> {
  loading: boolean
  data?: T
  closeWorkflow: () => void
  finishWorkflow: (data?: any) => void
}

export const WorkflowContext = createContext<IWorkflowContext>({
  loading: false,
  data: {},
  closeWorkflow: () => {},
  finishWorkflow: () => {},
})

export const useWorkflow = (): IWorkflowContext => useContext(WorkflowContext)
