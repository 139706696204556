import { ReactNode, useCallback, useMemo } from 'react'

import { CompanyType } from 'config/companies'
import { SERVICES } from 'config/services'
import { CompaniesContext } from 'providers/Companies/CompaniesContext'
import { useFeathers } from 'providers/Feathers'

export const CompaniesProvider = ({ children }: { children: ReactNode }) => {
  const { client } = useFeathers()

  const companiesService = client.service(SERVICES.COMPANIES)

  const findCompanies = useCallback(
    (params?: object) => companiesService.find(params),
    [companiesService]
  )

  const getCompany = useCallback(
    (id: string, params?: object) => companiesService.get(id, params),
    [companiesService]
  )

  const createCompany = useCallback(
    (data: CompanyType, params?: object) =>
      companiesService.create(data, params),
    [companiesService]
  )

  const updateCompany = useCallback(
    (id: string, data: CompanyType, params?: object) =>
      companiesService.patch(id, data, params),
    [companiesService]
  )

  const removeCompany = useCallback(
    (id: string, params?: object) => companiesService.remove(id, params),
    [companiesService]
  )

  const context = useMemo(
    () => ({
      companiesService,
      findCompanies,
      getCompany,
      createCompany,
      updateCompany,
      removeCompany,
    }),
    [
      companiesService,
      findCompanies,
      getCompany,
      createCompany,
      updateCompany,
      removeCompany,
    ]
  )

  return (
    <CompaniesContext.Provider value={context}>
      {children}
    </CompaniesContext.Provider>
  )
}
