import { ReactNode, useEffect, useMemo, useState } from 'react'

import { LOCAL_STORAGE } from 'config/localStorage'
import { DEFAULT_LOCALE, LANGUAGE_DEFAULT_LOCALE, LOCALES } from 'config/locale'
import { SERVICES } from 'config/services'
import { useFeathers } from 'providers/Feathers'
import { LocaleContext } from 'providers/Locale/LocaleContext'
import { useUser } from 'providers/User'
import { sanitize } from 'utils'
import { formatLocale } from 'utils/locales'

export const LocaleProvider = ({ children }: { children: ReactNode }) => {
  const { client } = useFeathers()
  const { isAuth, user } = useUser()

  const [locale, setLocale] = useState<LOCALES>(() => {
    // Local storage value
    const localStorageLocale = localStorage.getItem(LOCAL_STORAGE.LOCALE)
    if (
      typeof localStorageLocale === 'string' &&
      localStorageLocale in LOCALES
    ) {
      return localStorageLocale
    }

    // Navigator locale
    const navigatorLanguage = sanitize(navigator.language)
    if (navigatorLanguage in LOCALES) {
      return navigatorLanguage as LOCALES
    }

    // Navigator lang
    const navigatorLanguageDefaultLocale =
      // @ts-ignore
      LANGUAGE_DEFAULT_LOCALE[navigatorLanguage.substring(0, 2)]
    if (navigatorLanguageDefaultLocale in LOCALES) {
      return navigatorLanguageDefaultLocale
    }

    // Fallback
    return DEFAULT_LOCALE
  })

  const formattedLocale = useMemo(() => formatLocale(locale, '-'), [locale])

  useEffect(() => {
    if (!isAuth) {
      return
    }

    if (user?.lang === locale) {
      return
    }

    if (user?.id) {
      client.service(SERVICES.USERS).patch(user.id, { lang: locale })
    }
  }, [client, isAuth, user, locale])

  useEffect(() => {
    if (localStorage.getItem(LOCAL_STORAGE.LOCALE) !== locale) {
      localStorage.setItem(LOCAL_STORAGE.LOCALE, locale)
    }
  }, [locale])

  const context = useMemo(
    () => ({
      locale,
      setLocale,
      formattedLocale,
    }),
    [locale, formattedLocale]
  )

  return (
    <LocaleContext.Provider value={context}>{children}</LocaleContext.Provider>
  )
}
