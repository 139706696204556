// https://ant.design/components/drawer/

import { CloseOutlined, LeftOutlined } from '@ant-design/icons'
import { Layout, Drawer as AntdDrawer } from 'antd'
import { DrawerProps as AntdDrawerProps } from 'antd/lib/drawer'
import { memo, ElementType } from 'react'

import HelmetTitle from 'components/atoms/Helmet/Title'
import { MessageType } from 'config/message'
import { useFormatMessage } from 'hooks/message'
import { useDrawer } from 'providers/Drawer'
import { useDrawers } from 'providers/Drawers'

const { Content: AntdContent, Sider: AntdSider } = Layout

export interface DrawerProps extends AntdDrawerProps {
  sider?: ElementType
  content: ElementType
  id?: string
  helmetTitle?: MessageType
  drawerWidth?: string
}

const Drawer = ({
  sider: Sider,
  content: Content,
  helmetTitle,
  drawerWidth = '1000px',
  ...rest
}: DrawerProps) => {
  const { closeAllDrawers } = useDrawers()
  const { closeDrawer, index } = useDrawer()

  return (
    <>
      {typeof helmetTitle === 'string' && <HelmetTitle title={helmetTitle} />}
      <AntdDrawer
        closable={false}
        // onClose={closeAllDrawers}
        onClose={closeDrawer}
        width={`calc(${drawerWidth} - ${Math.min(index, 2) * 5}px)`}
        style={{
          borderTopLeftRadius: '24px',
          borderBottomLeftRadius: '24px',
        }}
        styles={{
          body: { padding: '0px' },
          mask: index > 0 ? { background: 'transparent' } : undefined,
        }}
        contentWrapperStyle={{
          borderTopLeftRadius: '24px',
          borderBottomLeftRadius: '24px',
        }}
        {...rest}
      >
        <Layout style={{ minHeight: '100%', maxHeight: '100%' }}>
          {Sider && (
            <AntdSider collapsed collapsedWidth="64px">
              <Sider />
            </AntdSider>
          )}
          <Layout style={{ overflow: 'auto' }}>
            <AntdContent>
              <Content />
            </AntdContent>
          </Layout>
        </Layout>
      </AntdDrawer>
    </>
  )
}

export default memo(Drawer)
