import { memo, useMemo } from 'react'

import Drawer from 'components/atoms/Drawer'
import Space from 'components/atoms/Space'
import Content from 'components/drawers/User/Content'
import UserAvatar from 'components/molecules/User/Avatar'
import UserName from 'components/molecules/User/Name'
import { useUserFirstName } from 'hooks/User/useUserFirstName'
import { useUserLastName } from 'hooks/User/useUserLastName'
import { useDrawer } from 'providers/Drawer'

export enum UserDrawerTab {
  details = 'details',
}

function User() {
  const { id } = useDrawer()
  const { firstName } = useUserFirstName(id)
  const { lastName } = useUserLastName(id)

  return (
    <Drawer
      open
      drawerWidth={'400px'}
      helmetTitle={firstName && lastName && `${firstName} ${lastName}`}
      title={
        <Space direction="vertical" size={4}>
          <span className="text-2xl font-normal">
            <UserName withId userId={id} />
          </span>
        </Space>
      }
      extra={<UserAvatar className="ml-3" size={70} userId={id} />}
      content={Content}
    />
  )
}

export default memo(User)
