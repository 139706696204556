import { ReactNode, useCallback, useMemo } from 'react'

import { FontType } from 'config/fonts'
import { SERVICES } from 'config/services'
import { useFeathers } from 'providers/Feathers'
import { FontsContext } from 'providers/Fonts/FontsContext'

export const FontsProvider = ({ children }: { children: ReactNode }) => {
  const { client } = useFeathers()

  const fontsService = client.service(SERVICES.FONTS)

  const findFonts = useCallback(
    (params?: object) => fontsService.find(params),
    [fontsService]
  )

  const getFont = useCallback(
    (id: string, params?: object) => fontsService.get(id, params),
    [fontsService]
  )

  const createFont = useCallback(
    (data: FontType, params?: object) => fontsService.create(data, params),
    [fontsService]
  )

  const updateFont = useCallback(
    (id: string, data: FontType, params?: object) =>
      fontsService.patch(id, data, params),
    [fontsService]
  )

  const removeFont = useCallback(
    (id: string, params?: object) => fontsService.remove(id, params),
    [fontsService]
  )

  const context = useMemo(
    () => ({
      fontsService,
      findFonts,
      getFont,
      createFont,
      updateFont,
      removeFont,
    }),
    [fontsService, findFonts, getFont, createFont, updateFont, removeFont]
  )

  return (
    <FontsContext.Provider value={context}>{children}</FontsContext.Provider>
  )
}
