import { ReactNode, useCallback, useMemo } from 'react'

import { SERVICES } from 'config/services'
import { TeamUserType } from 'config/teamUsers'
import { useFeathers } from 'providers/Feathers'
import { TeamUsersContext } from 'providers/TeamUsers/TeamUsersContext'

export const TeamUsersProvider = ({ children }: { children: ReactNode }) => {
  const { client } = useFeathers()

  const teamUsersService = client.service(SERVICES.TEAM_USERS)

  const findTeamUsers = useCallback(
    (params?: object) => teamUsersService.find(params),
    [teamUsersService]
  )

  const getTeamUser = useCallback(
    (id: string, params?: object) => teamUsersService.get(id, params),
    [teamUsersService]
  )

  const createTeamUser = useCallback(
    (data: TeamUserType | TeamUserType[], params?: object) =>
      teamUsersService.create(data, params),
    [teamUsersService]
  )

  const updateTeamUser = useCallback(
    (id: string, data: TeamUserType, params?: object) =>
      teamUsersService.patch(id, data, params),
    [teamUsersService]
  )

  const removeTeamUser = useCallback(
    (id: string, params?: object) => teamUsersService.remove(id, params),
    [teamUsersService]
  )

  const context = useMemo(
    () => ({
      teamUsersService,
      findTeamUsers,
      getTeamUser,
      createTeamUser,
      updateTeamUser,
      removeTeamUser,
    }),
    [
      teamUsersService,
      findTeamUsers,
      getTeamUser,
      createTeamUser,
      updateTeamUser,
      removeTeamUser,
    ]
  )

  return (
    <TeamUsersContext.Provider value={context}>
      {children}
    </TeamUsersContext.Provider>
  )
}
