import {
  Logout,
  Home2,
  AddCircle,
  Setting2,
  Lifebuoy,
  Colorfilter,
  Personalcard,
  Notepad2,
  MouseSquare,
  People,
  Gift,
  Chart2,
  UserOctagon,
  Profile2User,
  Buildings,
  Check,
  LinkSquare,
} from 'iconsax-react'
import { memo, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import Button from 'components/atoms/Button'
import Divider from 'components/atoms/Divider'
import Image from 'components/atoms/Image'
import Message from 'components/atoms/Intl/Message'
import Menu from 'components/atoms/Menu'
import Modal from 'components/atoms/Modal'
import Flex from 'components/atoms/Section/Flex'
import Space from 'components/atoms/Space'
import UserNameWithAvatar from 'components/organisms/User/NameWithAvatar'
import { LOCALES } from 'config/locale'
import { ROUTES } from 'config/routes'
import { USER_ROLES } from 'config/users'
import { useUserSignatureIds } from 'hooks/User/useUserSignatureIds'
import { useLocale } from 'providers/Locale'
import { useNotification } from 'providers/Notification'
import { usePricingTable } from 'providers/PricingTable'
import { useUser } from 'providers/User'
import { isSet } from 'utils'
import { isDevEnv } from 'utils/env'

const adminRoutesKeys = [
  ROUTES.ADMIN_PALETTES,
  ROUTES.ADMIN_SIGNATURES,
  ROUTES.ADMIN_TEMPLATES,
  ROUTES.ADMIN_USERS,
  ROUTES.ADMIN_BANNERS,
]

function Sider() {
  const location = useLocation()
  const navigate = useNavigate()
  const { locale, setLocale } = useLocale()
  const { infoNotification } = useNotification()
  const { setSeePricingTable } = usePricingTable()
  const { user, isAdmin, isFree, isRoleIn, isRoleNotIn, freeSignatures } =
    useUser()

  const { userSignaturesTotal } = useUserSignatureIds(user?.id)

  const [seeReferralForm, setSeeReferralForm] = useState<boolean>(false)

  const defaultKey = useMemo(
    () =>
      location?.pathname?.match(/^\/(.+?)(?:\/.+)?$/)?.[1] || 'mySignatures',
    [location]
  )

  const defaultOpenKeys = useMemo(() => {
    if (adminRoutesKeys.includes(defaultKey as ROUTES)) {
      return ['admin']
    }

    return undefined
  }, [defaultKey])

  const menu = useMemo(
    () => [
      {
        icon: <Home2 size={18} />,
        label: 'word.mySignatures',
        to: '/',
        key: 'mySignatures',
      },
      isRoleIn([USER_ROLES.ADMIN, USER_ROLES.OWNER, USER_ROLES.MANAGER]) &&
      user?.companyId
        ? {
            icon: <Profile2User size={18} />,
            label: 'word.users',
            to: `/${ROUTES.USERS}`,
            key: 'users',
          }
        : undefined,
      isRoleIn([USER_ROLES.ADMIN, USER_ROLES.OWNER, USER_ROLES.MANAGER]) &&
      user?.companyId
        ? {
            icon: <People size={18} />,
            label: 'word.teams',
            to: `/${ROUTES.TEAMS}`,
            key: 'teams',
          }
        : undefined,
      /* {
        icon: <Chart2 size={18} />,
        label: 'word.analytics',
        to: `/${ROUTES.ANALYTICS}`,
        key: 'analytics',
      }, */
      /* {
        icon: <UserOctagon size={18} />,
        label: 'word.myInformations',
        to: `/${ROUTES.PROFILE}`,
        key: 'profile',
      }, */
      isAdmin && {
        icon: <Setting2 size={18} />,
        key: 'admin',
        label: 'word.administration',
        children: [
          /* isAdmin && {
            icon: <Chart2 size={18} />,
            label: 'word.analytics',
            to: `/${ROUTES.ADMIN_ANALYTICS}`,
            key: 'admin-analytics',
          }, */
          isAdmin && {
            icon: <Buildings size={18} />,
            label: 'word.companies',
            to: `/${ROUTES.ADMIN_COMPANIES}`,
            key: 'admin-companies',
          },
          isAdmin && {
            icon: <Profile2User size={18} />,
            label: 'word.users',
            to: `/${ROUTES.ADMIN_USERS}`,
            key: 'admin-users',
          },
          isAdmin && {
            icon: <People size={18} />,
            label: 'word.teams',
            to: `/${ROUTES.ADMIN_TEAMS}`,
            key: 'admin-teams',
          },
          isAdmin && {
            icon: <Notepad2 size={18} />,
            label: 'word.signatures',
            to: `/${ROUTES.ADMIN_SIGNATURES}`,
            key: 'admin-signatures',
          },
          isAdmin && {
            icon: <LinkSquare size={18} />,
            label: 'word.signatureBanners',
            to: `/${ROUTES.ADMIN_SIGNATURE_BANNERS}`,
            key: 'admin-signature-banners',
          },
          isAdmin && {
            icon: <Colorfilter size={18} />,
            label: 'word.palettes',
            to: `/${ROUTES.ADMIN_PALETTES}`,
            key: 'admin-palettes',
          },
          isAdmin && {
            icon: <Personalcard size={18} />,
            label: 'word.templates',
            to: `/${ROUTES.ADMIN_TEMPLATES}`,
            key: 'admin-templates',
          },
          isAdmin && {
            icon: <MouseSquare size={18} />,
            label: 'word.banners',
            to: `/${ROUTES.ADMIN_BANNERS}`,
            key: 'admin-banners',
          },
        ],
      },
    ],
    [user, isAdmin, isRoleIn]
  )

  return (
    <>
      <div
        className="fixed flex h-screen w-[250px] flex-col space-y-4 border-r bg-white px-3 py-4 pt-8"
        style={{
          boxShadow:
            '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
        }}
      >
        <Link to={`/`} className="px-4">
          <Image className="h-[35px] w-auto" src="/emailee.svg" />
        </Link>
        <Button
          className="mx-1 flex flex-row items-center p-5"
          message="word.createSignature"
          icon={<AddCircle size={18} />}
          onClick={() => {
            if (
              isSet(userSignaturesTotal) &&
              freeSignatures > userSignaturesTotal
            ) {
              navigate(`/${ROUTES.INTRO_TEMPLATES}`)
            } else if (isFree) {
              setSeePricingTable(true)
            } else {
              infoNotification({
                message: 'word.userLimitSignaturesCreated2',
              })
            }
          }}
        />
        <Menu
          mode="inline"
          className="border-none"
          selectedKeys={defaultKey ? [defaultKey] : undefined}
          // defaultOpenKeys={defaultOpenKeys}
          defaultOpenKeys={['admin']}
          items={menu}
        />
        <Flex />
        <Space
          className="mx-1 cursor-pointer select-none pl-6 text-sm"
          onClick={() => {
            setLocale(locale === LOCALES.enGB ? LOCALES.frFR : LOCALES.enGB)
          }}
        >
          <span
            className={`fi ${
              locale === LOCALES.enGB ? 'fi-fr' : 'fi-gb'
            } text-base`}
          ></span>
          <Message
            value={`locale.${
              locale === LOCALES.enGB ? LOCALES.frFR : LOCALES.enGB
            }.name`}
          />
        </Space>
        {isDevEnv || user?.email?.endsWith('@emailee.io') ? (
          <Space
            align="start"
            className="mx-1 cursor-pointer select-none py-2 pl-6 text-sm"
            onClick={() => {
              setSeeReferralForm(true)
            }}
          >
            <Gift size={18} color="#0000ff" />
            <span className="text-[#0000ff]">
              <Message value="word.referAFriend" />
            </span>
          </Space>
        ) : undefined}
        {/* isAdmin ? (
          <Space
            align="start"
            className="mx-1 cursor-pointer select-none pb-2 pl-6 text-sm"
            onClick={() => {
              navigate(`/`)
            }}
          >
            <Lifebuoy size={18} />
            <Message value="word.helpCenter" />
          </Space>
        ) : undefined */}
        {isAdmin ? (
          <Space
            align="start"
            className="mx-1 cursor-pointer select-none pb-2 pl-6 text-sm"
            onClick={() => {
              navigate(`/${ROUTES.LOGOUT}`)
            }}
          >
            <Logout size={18} />
            <Message value="word.logout" />
          </Space>
        ) : undefined}
        <Divider />
        <div className="mx-1 truncate text-sm">
          <Link to={`/${ROUTES.PROFILE}`} className="px-4 pt-4 text-sm">
            <UserNameWithAvatar
              userId={user?.id}
              withBadge={
                isSet(user?.companyId) &&
                isRoleNotIn([USER_ROLES.MANAGER, USER_ROLES.USER])
              }
              withEmail={!user?.companyId}
              withCompany={!!user?.companyId}
              avatarProps={{ size: 40 }}
            />
          </Link>
        </div>
      </div>
      <Modal
        centered
        closable={false}
        open={seeReferralForm}
        onCancel={() => setSeeReferralForm(false)}
        footer={null}
      >
        {/* @ts-ignore */}
        <form-widget ucid="JiyIomXbcKLqVRZwt7CFsLACNy0"></form-widget>
      </Modal>
    </>
  )
}

export default memo(Sider)
