// import { TourProvider } from '@reactour/tour'
import { lazy, useEffect, useState } from 'react'
import { Route, RouteProps, useNavigate } from 'react-router-dom'

import { ProtectedRoute } from 'components/atoms/ProtectedRoute'
import RouterSwitch from 'components/atoms/RouterSwitch'
import Spin from 'components/atoms/Spin'
import { LOCAL_STORAGE } from 'config/localStorage'
import { ROUTES } from 'config/routes'
import { USER_ROLES } from 'config/users'
import { useUser } from 'providers/User'
import { AuthRoutes } from 'routes/Auth/routes'
import Flow from 'routes/Flow'
import { FlowRoutes } from 'routes/Flow/routes'
import Home from 'routes/Home'
import { HomeRoutes } from 'routes/Home/routes'
import { steps } from 'routes/steps'

const NoAccess = lazy(() => import('routes/NoAccess'))
const Logout = lazy(() => import('routes/Auth/Content/Logout'))

function Root() {
  const navigate = useNavigate()
  const { isAuth, isRoleIn, loading } = useUser()

  const [step, setStep] = useState(0)

  /* useEffect(() => {
    if (isAuth && localStorage.getItem(LOCAL_STORAGE.INTRO) !== 'true') {
      localStorage.setItem(LOCAL_STORAGE.INTRO, 'true')
      navigate(`/${ROUTES.INTRO_LOGO}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, localStorage]) */
  if (isAuth && localStorage.getItem(LOCAL_STORAGE.INTRO) !== 'true') {
    localStorage.setItem(LOCAL_STORAGE.INTRO, 'true')
  }

  const setCurrentStep = (currentStep: number) => {
    switch (currentStep) {
      case 0:
      case 1:
        // @ts-ignore
        navigate(`/${ROUTES.INTRO_LOGO}`, true)
        break
      case 2:
      case 3:
        // @ts-ignore
        navigate(`/${ROUTES.INTRO_PALETTES}`, true)
        break
      case 4:
      case 5:
        // @ts-ignore
        navigate(`/${ROUTES.INTRO_TEMPLATES}`, true)
        break
      case 6:
        // @ts-ignore
        navigate(`/${ROUTES.INTRO_LOGO}`, true)
        break
      default:
        break
    }

    setTimeout(() => {
      setStep(currentStep)
    }, 100)
  }

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spin />
      </div>
    )
  }

  return (
    /* <TourProvider
      styles={{
        maskArea: (base) => ({
          ...base,
          rx: 16,
        }),
        popover: (base) => ({
          ...base,
          borderRadius: 16,
        }),
      }}
      steps={steps}
      currentStep={step}
      // @ts-ignore
      setCurrentStep={setCurrentStep}
      defaultOpen={
        isAuth && localStorage.getItem(LOCAL_STORAGE.INTRO) !== 'true'
      }
      prevButton={({ Button, currentStep }) => (
        <Button kind="prev" hideArrow={currentStep === 0} />
      )}
      nextButton={({ Button, currentStep }) => (
        <Button kind="next" hideArrow={currentStep === steps?.length - 1} />
      )}
    > */
    <RouterSwitch>
      <Route path={`${ROUTES.LOGOUT}`} element={<Logout />} />
      {!isAuth &&
        AuthRoutes.map(({ ...props }: RouteProps, index: number) => (
          <Route key={`auth-${index}`} {...props} />
        ))}
      <Route element={<ProtectedRoute />}>
        <Route element={<Flow />}>
          {isAuth &&
            FlowRoutes.map(
              (
                {
                  roles,
                  element,
                  ...props
                }: RouteProps & {
                  roles?: USER_ROLES[]
                },
                index: number
              ) => (
                <Route
                  key={`flow-${index}`}
                  element={element}
                  errorElement={<NoAccess />}
                  {...props}
                />
              )
            )}
        </Route>
        <Route element={<Home />}>
          {isAuth &&
            HomeRoutes.map(
              (
                {
                  roles,
                  element,
                  ...props
                }: RouteProps & {
                  roles?: USER_ROLES[]
                },
                index: number
              ) => (
                <Route
                  key={`home-${index}`}
                  element={
                    !roles?.length || (roles?.length && isRoleIn(roles)) ? (
                      element
                    ) : (
                      <NoAccess />
                    )
                  }
                  errorElement={<NoAccess />}
                  {...props}
                />
              )
            )}
        </Route>
      </Route>
    </RouterSwitch>
    // </TourProvider>
  )
}

export default Root
