// https://ant.design/components/input/

import { Input as AntdInput, InputProps as AntdInputProps } from 'antd'
import { memo } from 'react'

import { MessageType } from 'config/message'
import { useFormatMessage } from 'hooks/message'

export type InputValueType = string | null
export interface InputProps<T = InputValueType>
  extends Omit<AntdInputProps, 'placeholder' | 'onChange' | 'value'> {
  placeholder?: MessageType
  onChange?: (value: T) => void
  value?: T
  myRef?: any
}

const Input = ({
  className = '',
  placeholder,
  onChange = () => {},
  style,
  value,
  myRef,
  ...rest
}: InputProps) => {
  const formatMessage = useFormatMessage()

  return (
    <AntdInput
      className={
        'min-h-[44px] min-w-full rounded-lg transition duration-200 ' +
        className
      }
      style={{ fontSize: '14px', ...style }}
      ref={myRef}
      size="large"
      autoComplete="off"
      data-lpignore="true"
      value={value ?? undefined}
      onChange={(event) => {
        event.stopPropagation()
        event.preventDefault()
        onChange(event?.target?.value || null)
      }}
      placeholder={
        placeholder ? (formatMessage(placeholder) as string) : undefined
      }
      {...rest}
    />
  )
}

export default memo(Input)
