import { ReactNode, useCallback, useMemo } from 'react'

import { PaletteType } from 'config/palettes'
import { SERVICES } from 'config/services'
import { useFeathers } from 'providers/Feathers'
import { PalettesContext } from 'providers/Palettes/PalettesContext'

export const PalettesProvider = ({ children }: { children: ReactNode }) => {
  const { client } = useFeathers()

  const palettesService = client.service(SERVICES.PALETTES)

  const findPalettes = useCallback(
    (params?: object) => palettesService.find(params),
    [palettesService]
  )

  const getPalette = useCallback(
    (id: string, params?: object) => palettesService.get(id, params),
    [palettesService]
  )

  const createPalette = useCallback(
    (data: PaletteType, params?: object) =>
      palettesService.create(data, params),
    [palettesService]
  )

  const updatePalette = useCallback(
    (id: string, data: PaletteType, params?: object) =>
      palettesService.patch(id, data, params),
    [palettesService]
  )

  const removePalette = useCallback(
    (id: string, params?: object) => palettesService.remove(id, params),
    [palettesService]
  )

  const context = useMemo(
    () => ({
      palettesService,
      findPalettes,
      getPalette,
      createPalette,
      updatePalette,
      removePalette,
    }),
    [
      palettesService,
      findPalettes,
      getPalette,
      createPalette,
      updatePalette,
      removePalette,
    ]
  )

  return (
    <PalettesContext.Provider value={context}>
      {children}
    </PalettesContext.Provider>
  )
}
