import { LOGO_TYPES } from 'config/logos'

export enum TEMPLATES_TYPES {
  MINIMALIST = 'MINIMALIST',
  DESIGN = 'DESIGN',
  VERTICAL = 'VERTICAL',
}

export enum TEMPLATES_FORMATS {
  HTML = 'HTML',
  SVG = 'SVG',
}

export type TemplateType = {
  id?: string

  companyId?: string

  // Generic informations
  name?: string
  type?: TEMPLATES_TYPES
  format?: TEMPLATES_FORMATS
  logoType?: LOGO_TYPES
  isPremium?: boolean
  isPublished?: boolean
  template?: string
  fields?: any // TODO: { [key: string]: string }
  colors?: any // TODO: { [key: string]: string }
  fonts?: any // TODO: { [key: string]: string }
  previewUrl?: string

  // Generic dates
  createdAt?: Date | string
  updatedAt?: Date | string
}
