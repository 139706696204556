import { Crown } from 'iconsax-react'
import { memo } from 'react'

import Message from 'components/atoms/Intl/Message'
import Space from 'components/atoms/Space'
import Tag, { TagProps } from 'components/atoms/Tag'

const TagProPlan = ({ className, ...rest }: TagProps) => {
  return (
    <Tag
      className={className || 'mr-0 rounded-full'}
      color="#0000ff"
      message={
        <Space size={4}>
          <span className="text-[12px]">
            <Message value="word.proPlan" />
          </span>
          <Crown variant="Bold" size={10} />
        </Space>
      }
      style={{
        padding: '0px 8px',
        fontWeight: 'bold',
        boxShadow: '0px 3.12298px 18.73788px 0px rgba(130, 142, 171, 0.16)',
      }}
      {...rest}
    />
  )
}

export default memo(TagProPlan)
