// https://ant.design/components/spin/

import { Spin as AntdSpin, SpinProps as AntdSpinProps } from 'antd'
import { memo } from 'react'

import { MessageType } from 'config/message'
import { useFormatMessage } from 'hooks/message'

export interface SpinProps extends Omit<AntdSpinProps, 'tip'> {
  tip?: MessageType
}

const Spin = ({ tip, ...rest }: SpinProps) => {
  const formatMessage = useFormatMessage()

  return <AntdSpin tip={formatMessage(tip) as string} {...rest} />
}

export default memo(Spin)
