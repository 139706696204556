import { ReactNode, useCallback, useMemo } from 'react'

import { SERVICES } from 'config/services'
import { SignatureBannerType } from 'config/signatureBanners'
import { useFeathers } from 'providers/Feathers'
import { SignatureBannersContext } from 'providers/SignatureBanners/SignatureBannersContext'

export const SignatureBannersProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const { client } = useFeathers()

  const signatureBannersService = client.service(SERVICES.SIGNATURE_BANNERS)

  const findSignatureBanners = useCallback(
    (params?: object) => signatureBannersService.find(params),
    [signatureBannersService]
  )

  const getSignatureBanner = useCallback(
    (id: string, params?: object) => signatureBannersService.get(id, params),
    [signatureBannersService]
  )

  const createSignatureBanner = useCallback(
    (data: SignatureBannerType, params?: object) =>
      signatureBannersService.create(data, params),
    [signatureBannersService]
  )

  const updateSignatureBanner = useCallback(
    (id: string | null, data: SignatureBannerType, params?: object) =>
      signatureBannersService.patch(id, data, params),
    [signatureBannersService]
  )

  const removeSignatureBanner = useCallback(
    (id: string, params?: object) => signatureBannersService.remove(id, params),
    [signatureBannersService]
  )

  const context = useMemo(
    () => ({
      signatureBannersService,
      findSignatureBanners,
      getSignatureBanner,
      createSignatureBanner,
      updateSignatureBanner,
      removeSignatureBanner,
    }),
    [
      signatureBannersService,
      findSignatureBanners,
      getSignatureBanner,
      createSignatureBanner,
      updateSignatureBanner,
      removeSignatureBanner,
    ]
  )

  return (
    <SignatureBannersContext.Provider value={context}>
      {children}
    </SignatureBannersContext.Provider>
  )
}
