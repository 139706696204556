import { ReactNode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import { RouterContext } from 'providers/Router/RouterContext'

export const RouterProvider = ({ children }: { children: ReactNode }) => (
  <RouterContext.Provider
    value={{
      defaultRoute: '/',
    }}
  >
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        {children}
      </QueryParamProvider>
    </BrowserRouter>
  </RouterContext.Provider>
)
