import { ReactNode, useCallback, useMemo } from 'react'

import { ConvertSignatureType } from 'config/convertSignatures'
import { SERVICES } from 'config/services'
import { ConvertSignaturesContext } from 'providers/ConvertSignatures/ConvertSignaturesContext'
import { useFeathers } from 'providers/Feathers'

export const ConvertSignaturesProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const { client } = useFeathers()

  const convertSignaturesService = client.service(SERVICES.CONVERT_SIGNATURES)

  const convertSignature = useCallback(
    (data: ConvertSignatureType, params?: object) =>
      convertSignaturesService.create(data, params),
    [convertSignaturesService]
  )

  const context = useMemo(
    () => ({
      convertSignaturesService,
      convertSignature,
    }),
    [convertSignaturesService, convertSignature]
  )

  return (
    <ConvertSignaturesContext.Provider value={context}>
      {children}
    </ConvertSignaturesContext.Provider>
  )
}
