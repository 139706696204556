import { createContext, useContext } from 'react'

import { SignatureBannerType } from 'config/signatureBanners'

export type FindSignatureBannersType = {
  limit: number
  skip: number
  total: number
  data: SignatureBannerType[]
}

interface ISignatureBannersContext {
  signatureBannersService: any
  findSignatureBanners: (params?: object) => Promise<FindSignatureBannersType>
  getSignatureBanner: (
    id: string,
    params?: object
  ) => Promise<SignatureBannerType>
  createSignatureBanner: (
    data: SignatureBannerType,
    params?: object
  ) => Promise<SignatureBannerType>
  updateSignatureBanner: (
    id: string | null,
    data: SignatureBannerType,
    params?: object
  ) => Promise<SignatureBannerType>
  removeSignatureBanner: (
    id: string,
    params?: object
  ) => Promise<SignatureBannerType>
}

export const SignatureBannersContext = createContext<ISignatureBannersContext>({
  signatureBannersService: {},
  findSignatureBanners: () =>
    Promise.resolve({
      limit: 0,
      skip: 0,
      total: 0,
      data: [],
    }),
  getSignatureBanner: () => Promise.resolve({}),
  createSignatureBanner: () => Promise.resolve({}),
  updateSignatureBanner: () => Promise.resolve({}),
  removeSignatureBanner: () => Promise.resolve({}),
})

export const useSignatureBanners = (): ISignatureBannersContext =>
  useContext(SignatureBannersContext)
