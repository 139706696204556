import { isValidElement, useCallback } from 'react'
import { useIntl } from 'react-intl'

import { MessageType } from 'config/message'
import { useLocale } from 'providers/Locale'

// Boolean to see translation keys
const seeTranslationKeys = false

export const useCheckMessage = () => {
  const intl = useIntl()

  return useCallback(
    (message?: MessageType) => {
      if (!message) {
        return false
      }

      if (isValidElement(message)) {
        return true
      }

      if (typeof message === 'string') {
        return Boolean(intl.messages[message])
      }

      // @ts-ignore
      if ('enGB' in message && 'frFR' in message) {
        return true
      }

      // @ts-ignore
      return Boolean(intl.messages[message.id])
    },
    [intl]
  )
}

export const useFormatMessage = () => {
  const intl = useIntl()
  const { locale } = useLocale()

  return useCallback(
    (message?: MessageType) => {
      if (!message) {
        return ''
      }

      if (isValidElement(message)) {
        return message
      }

      if (typeof message === 'string') {
        if (!seeTranslationKeys && intl.messages[message]) {
          return intl.formatMessage({ id: message })
        }
        return message
      }

      // @ts-ignore
      if ('enGB' in message) {
        return message[locale]
      }

      // @ts-ignore
      const { id, values } = message

      if (!seeTranslationKeys && intl.messages[id]) {
        return intl.formatMessage(
          { id },
          Object.entries(values).reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key]: value,
            }),
            {}
          )
        )
      }
      return id
    },
    [intl, locale]
  )
}
