import React, { Dispatch, SetStateAction, useContext } from 'react'

import { DEFAULT_LOCALE, LOCALES } from 'config/locale'

interface ILocaleContext {
  locale: LOCALES
  setLocale: Dispatch<SetStateAction<LOCALES>>
  formattedLocale: string
}

export const LocaleContext = React.createContext<ILocaleContext>({
  locale: DEFAULT_LOCALE,
  setLocale: () => {},
  formattedLocale: '',
})

export const useLocale = (): ILocaleContext => useContext(LocaleContext)
