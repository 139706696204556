import { ReactNode, useCallback, useMemo } from 'react'

import { SERVICES } from 'config/services'
import { TeamType } from 'config/teams'
import { useFeathers } from 'providers/Feathers'
import { TeamsContext } from 'providers/Teams/TeamsContext'

export const TeamsProvider = ({ children }: { children: ReactNode }) => {
  const { client } = useFeathers()

  const teamsService = client.service(SERVICES.TEAMS)

  const findTeams = useCallback(
    (params?: object) => teamsService.find(params),
    [teamsService]
  )

  const getTeam = useCallback(
    (id: string, params?: object) => teamsService.get(id, params),
    [teamsService]
  )

  const createTeam = useCallback(
    (data: TeamType, params?: object) => teamsService.create(data, params),
    [teamsService]
  )

  const updateTeam = useCallback(
    (id: string, data: TeamType, params?: object) =>
      teamsService.patch(id, data, params),
    [teamsService]
  )

  const removeTeam = useCallback(
    (id: string, params?: object) => teamsService.remove(id, params),
    [teamsService]
  )

  const context = useMemo(
    () => ({
      teamsService,
      findTeams,
      getTeam,
      createTeam,
      updateTeam,
      removeTeam,
    }),
    [teamsService, findTeams, getTeam, createTeam, updateTeam, removeTeam]
  )

  return (
    <TeamsContext.Provider value={context}>{children}</TeamsContext.Provider>
  )
}
