// https://ant.design/components/tag/

import { Tag as AntdTag, TagProps as AntdTagProps } from 'antd'
import { memo } from 'react'

import { MessageType } from 'config/message'
import { useFormatMessage } from 'hooks/message'

export interface TagProps extends AntdTagProps {
  message?: MessageType
}

const Tag = ({ message, children, color, style, ...rest }: TagProps) => {
  const formatMessage = useFormatMessage()

  return (
    <AntdTag
      style={{ borderRadius: '16px', padding: '2px 10px', ...style }}
      color={color}
      {...rest}
    >
      {children || formatMessage(message)}
    </AntdTag>
  )
}

export default memo(Tag)
