// https://ant.design/components/divider/

import { Divider as AntdDivider } from 'antd'
import { DividerProps as AntdDividerProps } from 'antd/es/divider'
import { memo } from 'react'

import { MessageType } from 'config/message'
import { useFormatMessage } from 'hooks/message'

export interface DividerProps extends AntdDividerProps {
  message?: MessageType
}

const Divider = ({ message, children, ...rest }: DividerProps) => {
  const formatMessage = useFormatMessage()

  return (
    <AntdDivider {...rest}>{children || formatMessage(message)}</AntdDivider>
  )
}

export default memo(Divider)
