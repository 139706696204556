import { ReactNode, useCallback, useMemo } from 'react'

import { SERVICES } from 'config/services'
import { TemplateType } from 'config/templates'
import { useFeathers } from 'providers/Feathers'
import { TemplatesContext } from 'providers/Templates/TemplatesContext'

export const TemplatesProvider = ({ children }: { children: ReactNode }) => {
  const { client } = useFeathers()

  const templatesService = client.service(SERVICES.TEMPLATES)

  const findTemplates = useCallback(
    (params?: object) => templatesService.find(params),
    [templatesService]
  )

  const getTemplate = useCallback(
    (id: string, params?: object) => templatesService.get(id, params),
    [templatesService]
  )

  const createTemplate = useCallback(
    (data: TemplateType, params?: object) =>
      templatesService.create(data, params),
    [templatesService]
  )

  const updateTemplate = useCallback(
    (id: string, data: TemplateType, params?: object) =>
      templatesService.patch(id, data, params),
    [templatesService]
  )

  const removeTemplate = useCallback(
    (id: string, params?: object) => templatesService.remove(id, params),
    [templatesService]
  )

  const context = useMemo(
    () => ({
      templatesService,
      findTemplates,
      getTemplate,
      createTemplate,
      updateTemplate,
      removeTemplate,
    }),
    [
      templatesService,
      findTemplates,
      getTemplate,
      createTemplate,
      updateTemplate,
      removeTemplate,
    ]
  )

  return (
    <TemplatesContext.Provider value={context}>
      {children}
    </TemplatesContext.Provider>
  )
}
