import { createContext, useContext } from 'react'

import { UserType } from 'config/users'

export type FindUsersType = {
  limit: number
  skip: number
  total: number
  data: UserType[]
}

interface IUsersContext {
  usersService: any
  findUsers: (params?: object) => Promise<FindUsersType>
  getUser: (id: string, params?: object) => Promise<UserType>
  createUser: (data: UserType, params?: object) => Promise<UserType>
  updateUser: (id: string, data: UserType, params?: object) => Promise<UserType>
  removeUser: (id: string, params?: object) => Promise<UserType>
}

export const UsersContext = createContext<IUsersContext>({
  usersService: {},
  findUsers: () =>
    Promise.resolve({
      limit: 0,
      skip: 0,
      total: 0,
      data: [],
    }),
  getUser: () => Promise.resolve({}),
  createUser: () => Promise.resolve({}),
  updateUser: () => Promise.resolve({}),
  removeUser: () => Promise.resolve({}),
})

export const useUsers = (): IUsersContext => useContext(UsersContext)
