import { memo } from 'react'

import Alert from 'components/atoms/Alert'
import { useCheckMessage } from 'hooks/message'

const ErrorOrDefault = ({
  error,
  afterClose,
  ...rest
}: {
  error?: any
  afterClose?: () => void
}) => {
  const checkMessage = useCheckMessage()

  return (
    <Alert
      type="warning"
      message={
        checkMessage(`error.${error?.message}`)
          ? `error.${error.message}`
          : 'error.default'
      }
      afterClose={afterClose}
      showIcon
      closable
      {...rest}
    />
  )
}

export default memo(ErrorOrDefault)
