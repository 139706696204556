// https://ant.design/components/menu/

import {
  Menu as AntdMenu,
  MenuItemProps as AntdMenuItemProps,
  MenuProps as AntdMenuProps,
} from 'antd'
import { memo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { MessageType } from 'config/message'
import { useFormatMessage } from 'hooks/message'
import { cleanArray } from 'utils'

interface MenuItemProps
  extends Omit<AntdMenuItemProps, 'children' | 'title' | 'label'> {
  children?: MenuItemProps[]
  title?: MessageType
  label?: MessageType
  key: string
  to?: string
}

export interface MenuProps extends Omit<AntdMenuProps, 'items'> {
  myRef?: any
  items: (MenuItemProps | undefined | null | false)[]
}

const Menu = ({ items = [], myRef, ...rest }: MenuProps) => {
  const navigate = useNavigate()
  const formatMessage = useFormatMessage()

  const formatMenu = useCallback(
    (items: MenuItemProps[]): any =>
      items.map((item) => {
        if ('divider' in item) {
          return item
        }
        const { children, title, label, onClick, to, ...rest } = item

        return {
          children: children ? formatMenu(cleanArray(children)) : undefined,
          title: title ? formatMessage(title) || title : undefined,
          label: label ? formatMessage(label) || label : undefined,
          onClick: (event: any) => {
            event.domEvent.stopPropagation()
            event.domEvent.preventDefault()
            if (onClick) {
              onClick(event)
            }
            if (to) {
              navigate(to)
            }
          },
          ...rest,
        }
      }),
    [formatMessage, navigate]
  )

  if (!items.length) {
    return null
  }

  return (
    // @ts-ignore
    <AntdMenu ref={myRef} {...rest} items={formatMenu(cleanArray(items))} />
  )
}

export default memo(Menu)
