// https://ant.design/components/form/

import { memo } from 'react'

import FormItem, { FormItemProps } from 'components/atoms/Form/FormItem'

export interface LabelProps extends FormItemProps {
  inline?: boolean
}

const Label = ({ inline, children, ...rest }: LabelProps) => {
  return (
    <FormItem
      labelCol={inline ? undefined : { span: 24 }}
      style={{ marginBottom: 'unset' }}
      {...rest}
    >
      {/* The empty tag below is here on purpose. This is to avoid FormItem to spread value and onChange to its child */}
      <>{children}</>
    </FormItem>
  )
}

export default memo(Label)
