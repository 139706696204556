import { createContext, useContext } from 'react'

import { ConvertSignatureType } from 'config/convertSignatures'

interface IConvertSignaturesContext {
  convertSignaturesService: any
  convertSignature: (
    data: ConvertSignatureType,
    params?: object
  ) => Promise<any>
}

export const ConvertSignaturesContext =
  createContext<IConvertSignaturesContext>({
    convertSignaturesService: {},
    convertSignature: () => Promise.resolve({}),
  })

export const useConvertSignatures = (): IConvertSignaturesContext =>
  useContext(ConvertSignaturesContext)
