import { ReactNode, useEffect, useMemo, useState } from 'react'

import { LOCAL_STORAGE } from 'config/localStorage'
import { THEMES, ThemesType } from 'config/themes'
import { ThemeContext } from 'providers/Theme/ThemeContext'

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [theme, setTheme] = useState<ThemesType>(
    localStorage.getItem(LOCAL_STORAGE.THEME) === THEMES.DARK ||
      (!(LOCAL_STORAGE.THEME in localStorage) &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
      ? THEMES.DARK
      : THEMES.LIGHT
  )

  useEffect(() => {
    if (theme === THEMES.DARK) {
      localStorage.setItem(LOCAL_STORAGE.THEME, THEMES.DARK)
    } else {
      localStorage.setItem(LOCAL_STORAGE.THEME, THEMES.LIGHT)
    }
  }, [theme])

  const toggleTheme = () => {
    setTheme((theme) => (theme === THEMES.DARK ? THEMES.LIGHT : THEMES.DARK))
  }

  const context = useMemo(
    () => ({
      setTheme,
      theme,
      toggleTheme,
    }),
    [theme]
  )

  return (
    <ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>
  )
}
