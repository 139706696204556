import { memo, useMemo, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
// TODO: import LoadingBar from 'react-top-loading-bar'

import { ROUTES } from 'config/routes'
import Header from 'routes/Flow/Header'
import Sider from 'routes/Flow/Sider'

const containsTerm = (string: string, term: string) => {
  if (string.includes(term)) {
    return string
  }
}

function Flow() {
  const location = useLocation()

  const [collapsed, setCollapsed] = useState<boolean>(true)

  const showMenu = useMemo(() => {
    switch (location?.pathname) {
      case containsTerm(location?.pathname, `/${ROUTES.DETAILS}`):
      case containsTerm(location?.pathname, `/${ROUTES.PALETTES}`):
      case containsTerm(location?.pathname, `/${ROUTES.TEMPLATES}`):
      case containsTerm(location?.pathname, `/${ROUTES.BANNERS}`):
        return true
    }
    return false
  }, [location])

  const showHeader = useMemo(() => {
    switch (location?.pathname) {
      case containsTerm(location?.pathname, `/${ROUTES.DETAILS}`):
      case containsTerm(location?.pathname, `/${ROUTES.PALETTES}`):
      case containsTerm(location?.pathname, `/${ROUTES.TEMPLATES}`):
      case containsTerm(location?.pathname, `/${ROUTES.BANNERS}`):
        return false
    }
    return true
  }, [location])

  const mesh = useMemo(() => {
    switch (location?.pathname) {
      case containsTerm(location?.pathname, `/${ROUTES.INTRO_TEMPLATES}`):
        return 'bg-white'
    }
    return 'mesh'
  }, [location])

  return (
    <div className="disable-scrollbars flex antialiased">
      <div className="hidden lg:block">
        <Sider
          showMenu={showMenu}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      </div>
      {showHeader ? (
        <Header showMenu={false} />
      ) : (
        <div className="block lg:hidden">
          <Header showMenu={true} />
        </div>
      )}
      <div
        className={`${mesh} mt-[60px] ${showHeader ? '' : 'lg:mt-0'} ${
          collapsed
            ? showMenu
              ? 'lg:ml-[80px]'
              : ''
            : showMenu
            ? 'lg:ml-[230px]'
            : ''
        } flex max-h-[100%] flex-1 flex-col`}
      >
        <Outlet />
      </div>
    </div>
  )
}

export default memo(Flow)
