// https://ant.design/components/modal/

import { Modal as AntdModal, ModalProps as AntdModalProps } from 'antd'
import { memo, ReactNode } from 'react'

import { MessageType } from 'config/message'
import { useFormatMessage } from 'hooks/message'

export interface ModalProps
  extends Omit<AntdModalProps, 'title' | 'children' | 'okText' | 'cancelText'> {
  title?: MessageType
  children?: ReactNode
  okText?: MessageType
  cancelText?: MessageType
}

const Modal = ({ title, okText, cancelText, ...rest }: ModalProps) => {
  const formatMessage = useFormatMessage()

  return (
    <AntdModal
      title={title ? formatMessage(title) : undefined}
      okText={okText ? formatMessage(okText) : undefined}
      cancelText={cancelText ? formatMessage(cancelText) : undefined}
      {...rest}
    />
  )
}

export default memo(Modal)
