import { memo } from 'react'

import { AvatarProps } from 'components/atoms/Avatar'
import Space from 'components/atoms/Space'
import CompanyName from 'components/molecules/Company/Name'
import TagFreePlan from 'components/molecules/Tag/FreePlan'
import TagProPlan from 'components/molecules/Tag/ProPlan'
import TagProPlusPlan from 'components/molecules/Tag/ProPlusPlan'
import UserAvatar from 'components/molecules/User/Avatar'
import UserEmail from 'components/molecules/User/Email'
import UserName from 'components/molecules/User/Name'
import { USER_PLANS } from 'config/users'
import { useUserCompanyId } from 'hooks/User/useUserCompanyId'
import { useUserPlan } from 'hooks/User/useUserPlan'

export interface UserNameWithAvatarProps {
  userId?: string
  withBadge?: boolean
  withEmail?: boolean
  withCompany?: boolean
  avatarProps?: AvatarProps
}

const UserNameWithAvatar = ({
  userId,
  withBadge,
  withEmail,
  withCompany,
  avatarProps = {},
  ...rest
}: UserNameWithAvatarProps) => {
  const { plan } = useUserPlan(userId)
  const { companyId } = useUserCompanyId(userId)

  const badge =
    plan === USER_PLANS.PROPLUS ? (
      <TagProPlusPlan className="mb-1" />
    ) : plan === USER_PLANS.PRO ? (
      <TagProPlan className="mb-1" />
    ) : (
      <TagFreePlan className="mb-1" />
    )

  return (
    <Space>
      <UserAvatar userId={userId} {...avatarProps} />
      {withEmail ? (
        <div className="flex flex-col items-start justify-center">
          {withBadge ? badge : undefined}
          <span className="leading-normal">
            <UserName userId={userId} {...rest} />
          </span>
          <span className="text-xs font-light text-zinc-500">
            <UserEmail userId={userId} />
          </span>
        </div>
      ) : undefined}
      {withCompany && companyId ? (
        <div className="flex flex-col items-start justify-center">
          {withBadge ? badge : undefined}
          <span className="leading-normal">
            <UserName userId={userId} {...rest} />
          </span>
          <span className="text-xs font-light text-zinc-500">
            <CompanyName companyId={companyId} />
          </span>
        </div>
      ) : undefined}
      {!withEmail && !withCompany ? (
        <>
          {withBadge ? badge : undefined}
          <UserName userId={userId} {...rest} />
        </>
      ) : undefined}
    </Space>
  )
}

export default memo(UserNameWithAvatar)
